import moment from "moment";
import { useTypes } from "../../utilities/types";

const Event = ({ data, formatDate }) => {
  const { shipmentStatus } = useTypes();

  return (
    <div className="row my-3">
      <div className="col-10 col-lg-3 col-xl-3 col-xxl-3">
        <div className="event-status-container">
          <span
            className="event-status-stamp"
            style={{
              backgroundColor: data.status.color,
            }}
          ></span>
          <label className="event-status-name">
            {shipmentStatus.find((s) => s.value === data.status.code).label}
          </label>
        </div>
      </div>
      <div className="col-2 col-lg-2 col-xl-2 col-xxl-2 text-end ps-0">
        <span className="event-time">
          {moment(data.eventDate).format(formatDate)}
        </span>
      </div>
      <div className="col-12 col-lg-7 col-xl-7 col-xxl-7">
        <label className="event-note">{data.note}</label>
        <label className="event-location">{data.location}</label>
      </div>
    </div>
  );
};

export default Event;
