import React, { useEffect } from "react";
import { useLogisticContext } from "./providers/CreateEditInLogistic";
import { callErrorToast } from "../../utilities";
import { Loader } from "../../Components/common/Loader";
import InputBox from "../../Components/common/InputBox";
import { LocationAutocomplete } from "../../Components/common/LocationAutocomplete";
import { locationNormalizer } from "./utilities";
import { useAppContext } from "../../AppProvider";

export const CreateEdit = ({ defaultLocation, callback }) => {
  const { dictionary } = useAppContext();

  const logisticContext = useLogisticContext();

  const entityContext = logisticContext || null;

  const handleAsyncSelectChange = (value) => {
    if (!defaultLocation) {
      entityContext.createLocation(locationNormalizer(value));
      return false;
    }
    const newContact = {
      ...locationNormalizer(value),
      id: entityContext.location.id,
    };
    entityContext.editLocation(newContact);
  };

  const handleLocationInputChange = (event) => {
    const { name, value } = event.target;
    entityContext.editLocation({ ...entityContext.location, [name]: value });
  };

  useEffect(() => {
    if (entityContext.locationError) {
      callErrorToast(entityContext.locationError, dictionary);
    }
  }, [entityContext.locationError]);

  useEffect(() => {
    //quando viene cambiata la location nella select, dopo aver creato o modificato il contatto viene chiamata una callback
    if (entityContext.location) {
      callback(entityContext.location);
    }
  }, [entityContext.location]);

  useEffect(() => {
    if (defaultLocation) {
      entityContext.setLocation(defaultLocation);
    }
  }, [defaultLocation]);

  return (
    <React.Fragment>
      {entityContext.locationLoader && (
        <Loader show={entityContext.locationLoader} />
      )}
      <div className="row">
        <div className="col">
          <LocationAutocomplete
            onLocationChange={handleAsyncSelectChange}
            placeholder={dictionary.actions.search}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-10">
          <label className="form-label">{dictionary.locations.address}:</label>
          <InputBox
            name="street"
            value={entityContext?.location?.street || ""}
            type="text"
            onChange={handleLocationInputChange}
            disabled
          />
        </div>
        <div className="col">
          <label className="form-label">
            {dictionary.locations.house_number}:
          </label>
          <InputBox
            name="houseNumber"
            value={entityContext?.location?.houseNumber || ""}
            type="text"
            onChange={handleLocationInputChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label className="form-label">{dictionary.locations.unit}:</label>
          <InputBox
            name="unit"
            value={entityContext?.location?.unit || ""}
            type="text"
            onChange={handleLocationInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">
            {dictionary.locations.postal_code}:
          </label>
          <InputBox
            name="postalCode"
            value={entityContext?.location?.postalCode || ""}
            type="text"
            onChange={handleLocationInputChange}
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.locations.district}:</label>
          <InputBox
            name="district"
            value={entityContext?.location?.district || ""}
            type="text"
            onChange={handleLocationInputChange}
            disabled
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.locations.city}:</label>
          <InputBox
            name="municipality"
            value={entityContext?.location?.municipality || ""}
            type="text"
            onChange={handleLocationInputChange}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label className="form-label">{dictionary.locations.province}:</label>
          <InputBox
            name="subRegionName"
            value={entityContext?.location?.subRegionName || ""}
            type="text"
            onChange={handleLocationInputChange}
            disabled
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.locations.region}:</label>
          <InputBox
            name="regionName"
            value={entityContext?.location?.regionName || ""}
            type="text"
            onChange={handleLocationInputChange}
            disabled
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.locations.country}:</label>
          <InputBox
            name="countryIsoCode3"
            value={entityContext?.location?.countryIsoCode3 || ""}
            type="text"
            onChange={handleLocationInputChange}
            disabled
          />
        </div>
      </div>
    </React.Fragment>
  );
};
