import { valueIsEmpty } from "../../utilities";

export const validation = (addressBook, dictionary) => {
  let errors = {};
  var { name, phone, email, location } = addressBook;

  if (valueIsEmpty(name)) errors["name"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(phone))
    errors["phone"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(email))
    errors["email"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(location.longitude))
    errors["location"] = dictionary.messages.mandatory_field;

  return errors;
};
