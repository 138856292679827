import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { callErrorToast } from "../../../utilities/index";

import { KPIService } from "../../../services/shipment/kpi";
import { dictionary } from "../../../utilities/dictionary";

export const ShipmentsContext = createContext();

const kpiService = new KPIService();

const ShipmentsProvider = ({ children, params, type }) => {
  const [kpi, setKpi] = useState(null);
  const [kpiError, setKpiError] = useState(null);
  const [kpiLoader, setKpiLoader] = useState(false);

  const prevParams = useRef();

  const getStatistics = () => {
    setKpiLoader(true);
    kpiService
      .statistics(params)
      .then((response) => {
        setKpi(response.data);
        setKpiLoader(false);
      })
      .catch((error) => {
        setKpiError(error);
        setKpiLoader(false);
      });
  };

  const getPerformances = () => {
    setKpiLoader(true);
    kpiService
      .performance(params)
      .then((response) => {
        setKpi(response.data);
        setKpiLoader(false);
      })
      .catch((error) => {
        setKpiError(error);
        setKpiLoader(false);
      });
  };

  useEffect(() => {
    if (kpiError) {
      callErrorToast(kpiError, dictionary);
    }
  }, [kpiError]);

  useEffect(() => {
    if (JSON.stringify(prevParams.current) !== JSON.stringify(params)) {
      prevParams.current = params;
      switch (type) {
        case "statistics":
          getStatistics();
          break;
        case "performances":
          getPerformances();
          break;
      }
    }
  }, [params]);

  return (
    <ShipmentsContext.Provider
      value={{
        kpi,
        kpiLoader,

        getStatistics,
        getPerformances,
      }}
    >
      {children}
    </ShipmentsContext.Provider>
  );
};

const useShipmentsContext = () => {
  return useContext(ShipmentsContext);
};

export { ShipmentsProvider, useShipmentsContext };
