import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import { FormFeedback, Button, Alert, Modal } from "reactstrap";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_blue.css";
// import "flatpickr/dist/l10n/it";
import moment from "moment";
import { Italian } from "flatpickr/dist/l10n/it"; // Importa la localizzazione italiana

import Contact, { defaultContact } from "../Shipments/contact";
import {
  defaultCollection,
  calculateCollectionDate,
  normalizer,
  validation,
} from "./utilities";
import {
  callErrorToast,
  callSuccessToast,
  valueIsEmpty,
} from "../../utilities";
import { AddressBookService as AddressBookShipmentService } from "../../services/shipment/addressBook";
import { LocationsService as LocationsShipmentService } from "../../services/shipment/locations";
import { CarriersService } from "../../services/registry/carriers";
import { CollectionsService } from "../../services/shipment/collections";
import { CarrierConfigurationsService } from "../../services/contract/carrierConfigurations";
import InputBox from "../../Components/common/InputBox";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { getAllCarrierConfigurations } from "../../utilities/asyncSelectCallAPI";
import { getCarrierConfigurationOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { useAppContext } from "../../AppProvider";

const collectionsService = new CollectionsService();
const addressBookShipmentService = new AddressBookShipmentService();
const locationsShipmentService = new LocationsShipmentService();
const carriersService = new CarriersService();
const carrierConfigurationsService = new CarrierConfigurationsService();

const CreateEdit = ({
  id,
  defaultContactId,
  carrierCode,
  unmountCallback,
  shipmentIds = [],
  standAlone = false,
}) => {
  const [collection, setCollection] = useState({ addressBook: defaultContact });
  // const [collectionSetting, setCollectionSetting] = useState();
  const [carriersWithStandaloneEnabled, setCarriersWithStandaloneEnabled] =
    useState([]);
  const [carrier, setCarrier] = useState({});
  const [carrierConfiguration, setCarrierConfiguration] = useState(null);
  const [errors, setErrors] = useState({});
  const [overwriteSettings, setOverwriteSettings] = useState(false);
  const [addressBookMode, setAddressBookMode] = useState(
    defaultContactId ? true : false
  );
  const [shipmentIdToAssociate, setShipmentIdToAssociate] =
    useState(shipmentIds);
  const [shipmentsInCollection, setShipmentsInCollection] = useState([]);

  const { dictionary, accessTokenPayload } = useAppContext();

  const getCollection = (id) => {
    return collectionsService
      .get(id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getShipmentsInCollection = (id) => {
    return collectionsService
      .getShipmentsInCollection(id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getCarrierConfiguration = (configurationId) => {
    carrierConfigurationsService
      .get(configurationId)
      .then((response) => {
        setCarrierConfiguration(response.data);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getAddressBook = (id) => {
    return addressBookShipmentService
      .get(id)
      .then((response) => {
        return {
          ...response.data,
          isNew: false,
        };
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getCarrier = (carrierCode) => {
    return carriersService
      .get(carrierCode)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getCarriersWithStandaloneEnabled = () => {
    return carriersService
      .all({
        search: [
          {
            selector: "collectionStandaloneCreationEnabled",
            value: true,
            preciseSearch: true,
          },
        ],
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  // const getCollectionSetting = (collection) => {
  //   //chiamata al BE per avere l'impostazione -> ricerca ownerId

  //   // let response = {
  //   //   morningOpenTime: "08:00",
  //   //   morningCloseTime: "12:00",
  //   //   afternoonOpenTime: "13:00",
  //   //   afternoonCloseTime: "19:00",
  //   //   addressBookId: "e7b71890-c9d8-424f-a272-61cc665f6a39",
  //   //   // addressBookId: null,
  //   // };
  //   let response = null;

  //   if (response) {
  //     collection = { ...collection, ...response };
  //     delete collection.addressBookId;
  //     if (response.addressBookId) {
  //       getAddressBook(response.addressBookId);
  //       setCollection(collection);
  //       return false;
  //     }
  //   }

  //   // setCollection(collection);
  //   // setContact(defaultContact);
  // };

  // const saveCollectionSettings = () => {
  //   //Chiamata per sovrascivere o creare le impostazioni
  // };

  //Create location in Shipment-service
  const createPickupLocation = (collection) => {
    locationsShipmentService
      .create(collection.addressBook.location)
      .then((response) => {
        collection.addressBook.locationId = response.data.id;
        collection.addressBook.ownerId = accessTokenPayload.owner;
        createPickupAddressBook(collection);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  //Create addressBook in Shipment-service
  const createPickupAddressBook = (collection) => {
    collection.addressBook.myAddress = true;
    addressBookShipmentService
      .create(collection.addressBook)
      .then((response) => {
        collection.addressBookId = response.data.id;
        createEditCollection(collection);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const bookCollection = (newCollection) => {
    collectionsService
      .book(newCollection.id)
      .then((response) => {
        unmountCallback();
        callSuccessToast({ status: 200 }, dictionary);
      })
      .catch((err) => {
        setCollection({ ...collection, id: newCollection.id });
        callErrorToast(err, dictionary);
      });
  };

  const createShipmentRelations = (collection) => {
    const createShipmentRelationsPromises = shipmentIdToAssociate.map(
      (elem) => {
        return new Promise((resolve, reject) => {
          collectionsService
            .createShipmentInCollection(collection.id, elem)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject();
            });
        });
      }
    );

    Promise.all(createShipmentRelationsPromises).then(() => {
      if (collection.book) {
        bookCollection(collection);
        return false;
      }
      unmountCallback();
      callSuccessToast({ status: 200 }, dictionary);
    });
  };

  const deleteShipmentRelations = (collection) => {
    collectionsService
      .deleteShipmentRelations(collection.id)
      .then((response) => {
        if (shipmentIdToAssociate.length > 0) {
          createShipmentRelations(collection);
          return false;
        }

        if (collection.book) {
          bookCollection(collection);
          return false;
        }
        unmountCallback();
        callSuccessToast({ status: 200 }, dictionary);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const createEditCollection = (collection) => {
    if (collection.id) {
      collectionsService
        .edit(collection)
        .then((response) => {
          //Se ci sono spedizioni da associare
          if (shipmentIdToAssociate.length > 0) {
            deleteShipmentRelations({ ...collection, ...response.data });
            return false;
          }
          if (collection.book) {
            bookCollection(collection);
            return false;
          }
          unmountCallback();
          callSuccessToast({ status: 200 }, dictionary);
        })
        .catch((err) => {
          callErrorToast(err, dictionary);
        });
    } else {
      collectionsService
        .create(collection)
        .then((response) => {
          createShipmentRelations({ ...collection, ...response.data });
        })
        .catch((err) => {
          callErrorToast(err, dictionary);
        });
    }
  };

  const save = (andBook = false) => {
    const normalized = normalizer(
      collection,
      carrier,
      carrierConfiguration,
      andBook
    );

    const errors = validation(
      normalized,
      carrierConfiguration,
      standAlone,
      dictionary
    );

    if (valueIsEmpty(errors)) {
      //AddressBook da creare
      if (normalized.addressBook.isNew) {
        createPickupLocation(normalized);
        return false;
      }
      createEditCollection(normalized);
    }

    setErrors(errors);
  };

  const onChangeTimePicker = (name, hour) => {
    setCollection({
      ...collection,
      [name]: hour,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (errors) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
    setCollection({ ...collection, [name]: value });
  };

  const handleAsyncSelectChange = (selector, value) => {
    if (value) {
      delete errors[selector];
      setErrors(errors);
    }
    setCarrierConfiguration(value);
  };

  useEffect(() => {
    if (carrierConfiguration) {
      const carrier = carriersWithStandaloneEnabled.find(
        (elem) => elem.code === carrierConfiguration.carrierCode
      );
      if (carrier) {
        setCollection((collection) => {
          return {
            ...collection,
            date: calculateCollectionDate(carrier?.collectionCutoffTime),
          };
        });
      }
    }
  }, [carrierConfiguration]);

  useEffect(() => {
    let getAddressBookPromise,
      getCarrierPromise,
      getCollectionPromise,
      getShipmentsInCollectionPromise,
      getCarriersWithStandaloneEnabledPromise;

    // let getCollectionSettingPromise = new Promise((resolve, reject) => {
    //   resolve(getCollectionSetting(accessTokenPayload.owner));
    // });

    if (id) {
      getCollectionPromise = new Promise((resolve, reject) => {
        resolve(getCollection(id));
      });
      getShipmentsInCollectionPromise = new Promise((resolve, reject) => {
        resolve(getShipmentsInCollection(id));
      });
    }
    if (defaultContactId) {
      getAddressBookPromise = new Promise((resolve, reject) => {
        resolve(getAddressBook(defaultContactId));
      });
    }
    if (carrierCode) {
      getCarrierPromise = new Promise((resolve, reject) => {
        resolve(getCarrier(carrierCode));
      });
    }

    getCarriersWithStandaloneEnabledPromise = new Promise((resolve, reject) => {
      resolve(getCarriersWithStandaloneEnabled());
    });

    Promise.all([
      // getCollectionSettingPromise,
      getCollectionPromise,
      getShipmentsInCollectionPromise,
      getAddressBookPromise,
      getCarrierPromise,
      getCarriersWithStandaloneEnabledPromise,
    ]).then(
      ([
        collection,
        shipmentsInCollection,
        addressBook,
        carrier,
        carriersWithStandaloneEnabled,
      ]) => {
        let newCollection = {
          ...defaultCollection,
          // ...collectionSetting,
          addressBook: { ...defaultContact, isNew: false },
        };

        if (collection) {
          newCollection = { ...collection };
        }

        //if it is not being edited I take the collections settings
        // if (!id && collectionSetting) {
        //   newCollection = { ...collectionSetting };
        // }

        if (addressBook) {
          newCollection.addressBook = { ...addressBook };
        }

        if (carrier) {
          setCarrier(carrier);
        }

        if (newCollection.date) {
          newCollection.date = new Date(newCollection.date);
        } else {
          newCollection.date = calculateCollectionDate(
            carrier?.collectionCutoffTime
          );
        }

        //TODO se il BE mi passa sempre l'oggetto configuration non mi serve fare la chiamata
        if (newCollection.carrierConfigurationId) {
          getCarrierConfiguration(newCollection.carrierConfigurationId);
        }

        if (shipmentsInCollection?.length > 0) {
          setShipmentsInCollection(shipmentsInCollection);
        }

        //Per permettere la ricerca nella select dei soli vettori che accettano lo standAlone serve il codice vettore nella proprietà value
        setCarriersWithStandaloneEnabled(
          carriersWithStandaloneEnabled.map((elem) => {
            return { ...elem, value: elem.code };
          })
        );
        setCollection(newCollection);
      }
    );
  }, []);

  return (
    <React.Fragment>
      {/* Se il giorno selezionato è oggi e l'orario di cutoff è stato superato */}
      {moment(collection.date).isSame(moment(), "day") &&
        moment().isAfter(moment(carrier?.collectionCutoffTime, "HH:mm")) && (
          <div className="row mb-3">
            <div className="col">
              <Alert color="warning">
                {dictionary.collections.cut_off_passed} (
                {carrier.collectionCutoffTime})
              </Alert>
            </div>
          </div>
        )}

      {((id && collection.carrierConfigurationId) || (!id && standAlone)) && (
        <div className="row mb-3">
          <div className="col">
            <label className="form-label">
              {dictionary.integrations.carrier_integration}:
            </label>
            <AsyncSelect
              name="carrierConfiguration"
              optionValue="id"
              optionLabel="name"
              isClearable={false}
              value={carrierConfiguration}
              onSearch={handleAsyncSelectChange}
              loadOptions={(inputValue, pagination) => {
                return getAllCarrierConfigurations(
                  inputValue,
                  pagination,
                  {
                    orderBy: "asc",
                    selector: "name",
                  },
                  [
                    {
                      selector: "carrierCode",
                      value: carriersWithStandaloneEnabled,
                    },
                  ]
                );
              }}
              optionStyle={(option) =>
                getCarrierConfigurationOptionStyle(option, dictionary)
              }
              error={errors["carrierConfiguration"]}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <label className="form-label">
            {dictionary.dates.collection_date}:
          </label>
          <Flatpickr
            className={`form-control date-buttons ${
              errors["date"] && "is-invalid"
            }`}
            placeholder={dictionary.actions.select}
            options={{
              dateFormat: "d/m/Y",
              firstDayOfWeek: 1,
              locale: Italian,
            }}
            onChange={(date, dateString) => {
              setCollection({ ...collection, date: date[0] });
            }}
            value={collection.date}
          />
          {errors["date"] && (
            <FormFeedback type="invalid" className="d-block">
              {errors["date"]}
            </FormFeedback>
          )}
        </div>
        <div className="col">
          <label className="form-label">
            {dictionary.references.external_reference}:
          </label>
          <InputBox
            name="externalReference"
            value={collection.externalReference || ""}
            placeholder={"EXTREF123"}
            type="text"
            onChange={handleInputChange}
          />
        </div>
        {((id && collection.carrierConfigurationId) || (!id && standAlone)) && (
          <>
            <div className="col">
              <label className="form-label">
                {dictionary.parcels.number_of_packages_2}:
              </label>
              <InputBox
                name="numPackages"
                value={collection.numPackages || ""}
                placeholder={"1"}
                type="positive-integer-number"
                onChange={handleInputChange}
                error={errors && errors["numPackages"]}
              />
            </div>
            <div className="col">
              <label className="form-label">
                {dictionary.dimensions.total_weight} (kg):
              </label>
              <InputBox
                name="totalWeight"
                value={collection.totalWeight || ""}
                placeholder={"1"}
                type="positive-number"
                onChange={handleInputChange}
                precision={3}
                min={1}
                required={true}
                error={errors && errors["totalWeight"]}
              />
            </div>
          </>
        )}
      </div>
      <div className="row mt-3">
        <h5>{dictionary.times.time_slots}</h5>
        <div className="col">
          <label className="form-label">
            {dictionary.collections.opening_morning}:
          </label>
          <Flatpickr
            className="form-control date-buttons"
            placeholder={dictionary.actions.select}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
              maxTime: collection.morningCloseTime,
            }}
            onChange={(date, dateString) =>
              onChangeTimePicker("morningOpenTime", dateString)
            }
            value={collection.morningOpenTime}
          />
          {errors["morningOpenTime"] && (
            <FormFeedback type="invalid" className="d-block">
              {errors["morningOpenTime"]}
            </FormFeedback>
          )}
        </div>
        <div className="col">
          <label className="form-label">
            {dictionary.collections.closing_morning}:
          </label>
          <Flatpickr
            className="form-control date-buttons"
            placeholder={dictionary.actions.select}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
              minTime: collection.morningOpenTime,
              maxTime: collection.afternoonOpenTime,
            }}
            onChange={(date, dateString) =>
              setCollection({
                ...collection,
                morningCloseTime: dateString,
              })
            }
            value={collection.morningCloseTime}
          />
        </div>
        <div className="col">
          <label className="form-label">
            {dictionary.collections.opening_afternoon}:
          </label>
          <Flatpickr
            className="form-control date-buttons"
            placeholder={dictionary.actions.select}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
              minTime: collection.morningCloseTime,
              maxTime: collection.afternoonCloseTime,
            }}
            onChange={(date, dateString) =>
              setCollection({
                ...collection,
                afternoonOpenTime: dateString,
              })
            }
            value={collection.afternoonOpenTime}
          />
        </div>
        <div className="col">
          <label className="form-label">
            {dictionary.collections.closing_afternoon}:
          </label>
          <Flatpickr
            className="form-control date-buttons"
            placeholder={dictionary.actions.select}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
              minTime: collection.afternoonOpenTime,
            }}
            onChange={(date, dateString) =>
              setCollection({
                ...collection,
                afternoonCloseTime: dateString,
              })
            }
            value={collection.afternoonCloseTime}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <div className="row">
            <div className="col">
              <h5>{dictionary.registries.pickup}</h5>
            </div>
            <div className="col-8 text-end">
              {defaultContactId && (
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                  onClick={() => {
                    setAddressBookMode(true);
                    getAddressBook(defaultContactId);
                  }}
                >
                  <i className="ph-map-pin-line ph-sm me-2"></i>
                  {dictionary.shipments.use_shipment_pickup}
                </button>
              )}
            </div>
          </div>
          <Contact
            data={collection.addressBook}
            edit={(addressBook) => {
              setCollection({
                ...collection,
                addressBook: { ...addressBook, isNew: true },
              });
            }}
            filters={{
              sort: {
                property: "name",
                mode: "asc",
              },
              search: {
                myAddress: "true",
                ownerId: accessTokenPayload.owner,
              },
            }}
            setErrors={setErrors}
            errors={errors}
            mode={addressBookMode}
            setMode={setAddressBookMode}
          />
        </div>
      </div>
      <div className="row mt-3 align-items-center">
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (unmountCallback) {
                  unmountCallback();
                }
              }}
            >
              {dictionary.actions.cancel}
            </button>
            <Button
              type="button"
              className="btn ms-3"
              onClick={() => {
                save(false);
              }}
            >
              {dictionary.actions.save}
            </Button>
            <Button
              type="button"
              className="btn ms-1"
              onClick={() => {
                save(true);
              }}
            >
              {dictionary.actions.save} {dictionary.words.and}{" "}
              {dictionary.actions.book}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateEdit;
