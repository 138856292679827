import React, { useState } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import "../../assets/scss/pages/homepage.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagsShopping,
  faHandHoldingBox,
  faLongArrowRight,
  faTruckFast,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Card } from "react-bootstrap";

import { CardBody } from "reactstrap";
import Collections from "./Collections";
import { useAppContext } from "../../AppProvider";
import { useTypes } from "../../utilities/types";
import { ReportCard } from "../KPI/ReportCard";
import { ShipmentsProvider } from "../KPI/providers/Shipments";
import moment from "moment";
import { ShipmentsByStatus } from "../KPI/ShipmentsByStatus";
import { ShipmentsByCarrier } from "../KPI/ShipmentsByCarrier";
import { AverageDeliveryTimeForShipmentsPerService } from "../KPI/AverageDeliveryTimeForShipmentsPerService";
import { DatePickerFilter } from "../../Components/common/List/Filters/DatePickerFilter";

const Dashboard = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const [dateRangeForMyShipments, setDateRangeForMyShipments] = useState([
    moment().subtract(1, "months").startOf("day").toDate(),
    moment().endOf("day").toDate(),
  ]);
  const { dictionary, owner } = useAppContext();
  const types = useTypes();
  const shipmentStatus = types.shipmentStatus;

  return (
    <React.Fragment>
      <div className="homepage-header">
        <h1 className="title">
          {dictionary.words.welcome_back} {accessTokenPayload.name}!
        </h1>
        <span className="role">{owner?.name}</span>
        <div className="rapid-links">
          <Link to={"/shipments"} className="link">
            <FontAwesomeIcon icon={faTruckFast} size="2x" />
            <span>{dictionary.shipments.my_shipments.toUpperCase()}</span>
          </Link>
          <Link to={"/orders"} className="link">
            <FontAwesomeIcon icon={faBagsShopping} size="2x" />
            <span>{dictionary.orders.my_orders.toUpperCase()}</span>
          </Link>
          <Link to={"/collections"} className="link">
            <FontAwesomeIcon icon={faHandHoldingBox} size="2x" />
            <span>{dictionary.collections.my_collections.toUpperCase()}</span>
          </Link>
        </div>
      </div>
      <section className="mt-4">
        <h2>{dictionary.words.overview}</h2>
        <div className="report-container">
          <ShipmentsProvider
            type={"statistics"}
            params={{
              start: moment().startOf("day").format(),
              end: moment().endOf("day").format(),
            }}
          >
            <ReportCard
              title={dictionary.shipments.my_shipments}
              subtitle={dictionary.times.today}
              path={"/shipments"}
              filters={{
                creationDate: [
                  moment().startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
              }}
            />
          </ShipmentsProvider>

          <ShipmentsProvider
            type={"statistics"}
            params={{
              start: moment().subtract(1, "months").startOf("day").format(),
              end: moment().endOf("day").format(),
            }}
          >
            <ReportCard
              title={dictionary.shipments.my_shipments}
              subtitle={dictionary.times.last_30_days}
              path={"/shipments"}
              filters={{
                creationDate: [
                  moment().subtract(1, "months").startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
              }}
            />
          </ShipmentsProvider>
          <ShipmentsProvider
            type={"statistics"}
            params={{
              start: moment().subtract(2, "months").startOf("day").format(),
              end: moment().endOf("day").format(),
              statuses: ["EXCEPTION", "FAILED_DLV"],
            }}
          >
            <ReportCard
              title={dictionary.shipments.shipments_to_be_verified}
              subtitle={dictionary.times.last_60_days}
              path={"/shipments"}
              filters={{
                creationDate: [
                  moment().subtract(2, "months").startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
                "status.code": [
                  shipmentStatus.find((x) => x.value === "EXCEPTION"),
                  shipmentStatus.find((x) => x.value === "FAILED_DLV"),
                ],
              }}
            />
          </ShipmentsProvider>
        </div>
      </section>
      <section className="my-3">
        <div className="row">
          <div className="col-8">
            <h2>{dictionary.shipments.my_shipments}</h2>
          </div>
          <div className="col">
            <DatePickerFilter
              value={dateRangeForMyShipments}
              onChange={(fieldName, dates) => {
                setDateRangeForMyShipments(dates);
              }}
            ></DatePickerFilter>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <ShipmentsProvider
              type={"statistics"}
              params={{
                start: moment(dateRangeForMyShipments[0])
                  .startOf("day")
                  .format(),
                end: moment(dateRangeForMyShipments[1]).endOf("day").format(),
                statuses: [
                  "BOOKED",
                  "CONFIRMED",
                  "AVAILABLE",
                  "IN_TRANSIT",
                  "OUT_OF_DLV",
                  "EXCEPTION",
                  "FAILED_DLV",
                  "DELIVERED",
                  "INFO_RCVD",
                  "RET_SENDER",
                  "LOST",
                ],
              }}
            >
              <ShipmentsByStatus
                subtitle={
                  moment(dateRangeForMyShipments[0]).isSame(
                    moment(dateRangeForMyShipments[1]),
                    "day"
                  )
                    ? moment(dateRangeForMyShipments[0]).format("DD/MM/YYYY")
                    : moment(dateRangeForMyShipments[0]).format("DD/MM/YYYY") +
                      " - " +
                      moment(dateRangeForMyShipments[1]).format("DD/MM/YYYY")
                }
              />
            </ShipmentsProvider>
          </div>
          <div className="col-3">
            <ShipmentsProvider
              type={"statistics"}
              params={{
                start: moment(dateRangeForMyShipments[0])
                  .startOf("day")
                  .format(),
                end: moment(dateRangeForMyShipments[1]).endOf("day").format(),
                statuses: [
                  "BOOKED",
                  "CONFIRMED",
                  "AVAILABLE",
                  "IN_TRANSIT",
                  "OUT_OF_DLV",
                  "EXCEPTION",
                  "FAILED_DLV",
                  "DELIVERED",
                  "INFO_RCVD",
                  "RET_SENDER",
                  "LOST",
                ],
              }}
            >
              <ShipmentsByCarrier
                subtitle={
                  moment(dateRangeForMyShipments[0]).isSame(
                    moment(dateRangeForMyShipments[1]),
                    "day"
                  )
                    ? moment(dateRangeForMyShipments[0]).format("DD/MM/YYYY")
                    : moment(dateRangeForMyShipments[0]).format("DD/MM/YYYY") +
                      " - " +
                      moment(dateRangeForMyShipments[1]).format("DD/MM/YYYY")
                }
              />
            </ShipmentsProvider>
          </div>
          <div className="col-6">
            <ShipmentsProvider
              type={"performances"}
              params={{
                start: moment(dateRangeForMyShipments[0])
                  .startOf("day")
                  .format(),
                end: moment(dateRangeForMyShipments[1]).endOf("day").format(),
              }}
            >
              <AverageDeliveryTimeForShipmentsPerService
                subtitle={
                  moment(dateRangeForMyShipments[0]).isSame(
                    moment(dateRangeForMyShipments[1]),
                    "day"
                  )
                    ? moment(dateRangeForMyShipments[0]).format("DD/MM/YYYY")
                    : moment(dateRangeForMyShipments[0]).format("DD/MM/YYYY") +
                      " - " +
                      moment(dateRangeForMyShipments[1]).format("DD/MM/YYYY")
                }
              />
            </ShipmentsProvider>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
