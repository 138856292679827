import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLoader,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it"; // Importa la localizzazione italiana
import { useAppContext } from "../../AppProvider";
import { useDetailsCodCollectionContext } from "./providers/Details";
import InputBox from "../../Components/common/InputBox";
import CODCollectionShipments from "../CODCollectionShipments";
import moment from "moment";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";

const CODCollectionHandler = () => {
  const action = window.location.pathname.split("/")[2];
  const { id } = useParams();
  const { dictionary } = useAppContext();

  let context;
  switch (action) {
    case "details":
      context = useDetailsCodCollectionContext();
      break;
  }
  useEffect(() => {
    if (action === "edit" || action === "details") {
      context.getCodCollection(id);
    }
  }, []);

  return (
    <React.Fragment>
      <h2>{context?.title}</h2>
      <div className="row">
        <div className="col">
          <div className="box-container mb-3">
            {context?.codCollectionLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div
                  className="d-grid"
                  style={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: `1.5fr 1.5fr 1.5fr 1.5fr 1fr 1fr 0.75fr`,
                    gap: "0.5rem",
                  }}
                >
                  <div hidden={action !== "details"}>
                    <label className="label-2">
                      {dictionary.registries.supplier}:
                    </label>
                    <label className="label-3">
                      {context?.codCollection?.supplierName}
                    </label>
                  </div>
                  <div hidden={action !== "details"}>
                    <label className="label-2">
                      {dictionary.registries.customer}:
                    </label>
                    <label className="label-3">
                      {context?.codCollection?.customerName}
                    </label>
                  </div>
                  <div hidden={action !== "details"}>
                    <label className="label-2">
                      {dictionary.dates.creation_date}:
                    </label>
                    <InputBox
                      name="creationDate"
                      value={
                        moment(context?.codCollection?.creationDate).format(
                          "DD/MM/YYYY HH:mm"
                        ) || ""
                      }
                      type="text"
                      disabled={action === "details"}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.references.external_reference}:
                    </label>
                    <InputBox
                      name="externalReference"
                      value={context?.codCollection?.externalReference || ""}
                      placeholder={"EXT REF"}
                      type="text"
                      // onChange={handleInputChange}
                      disabled={action === "details"}
                      error={
                        context.codCollectionError?.response?.data
                          ?.externalReference
                      }
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.dimensions.expected_amount} (€):
                    </label>
                    <InputBox
                      name="expectedAmount"
                      value={context?.codCollection?.expectedAmount || ""}
                      placeholder={""}
                      type="positive-number"
                      // onChange={handleInputChange}
                      disabled={action === "details"}
                      error={
                        context.codCollectionError?.response?.data
                          ?.expectedAmount
                      }
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.dimensions.collected_amount} (€):
                    </label>
                    <InputBox
                      name="collectedAmount"
                      value={context?.codCollection?.collectedAmount || ""}
                      placeholder={""}
                      type="positive-number"
                      // onChange={handleInputChange}
                      disabled={action === "details"}
                      error={
                        context.codCollectionError?.response?.data
                          ?.collectedAmount
                      }
                    />
                  </div>
                  <div hidden={action !== "details"}>
                    <label className="label-2">
                      {dictionary.words.delta} (€):
                    </label>
                    <InputBox
                      name="delta"
                      value={
                        context?.codCollection?.collectedAmount &&
                        context?.codCollection?.expectedAmount
                          ? `${(
                              context?.codCollection?.collectedAmount -
                              context?.codCollection?.expectedAmount
                            ).toFixed(2)}`
                          : ""
                      }
                      placeholder={""}
                      type="text"
                      // onChange={handleInputChange}
                      disabled={action === "details"}
                      error={
                        context.codCollectionError?.response?.data
                          ?.collectedAmount
                      }
                    />
                  </div>
                </div>
                <div
                  className="d-grid align-items-center"
                  style={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: `1fr 5fr`,
                    gap: "0.5rem",
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.dates.collection_date}:
                    </label>
                    <Flatpickr
                      name="collectionDate"
                      placeholder={dictionary.actions.select}
                      options={{
                        dateFormat: "d/m/Y",
                      }}
                      //   onChange={handleDatePickerChange}
                      value={
                        context?.codCollection?.collectionDate &&
                        new Date(context?.codCollection?.collectionDate)
                      }
                      disabled={action === "details"}
                    />
                  </div>
                  <div>
                    <label className="label-2">{dictionary.words.note}:</label>
                    <InputBox
                      name="note"
                      value={context?.codCollection?.note || ""}
                      placeholder={""}
                      type="positive-number"
                      // onChange={handleInputChange}
                      disabled={action === "details"}
                      error={context.codCollectionError?.response?.data?.note}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <h3>{dictionary.shipments.shipments}</h3>
      <CODCollectionShipments parentId={id} action={action} />

      <div className="row">
        <div className="col">
          {
            <Link
              to={`/cod-collections`}
              className="button-1 button-1-transparent"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> {dictionary.words.back}
            </Link>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default CODCollectionHandler;
