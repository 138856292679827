import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { ShipmentsService } from "../../../services/shipment/shipments";
import { OrdersService } from "../../../services/logistic/orders";
import { OrderDetailsService } from "../../../services/logistic/orderDetails";
import { WarehousesService } from "../../../services/logistic/warehouses";
import { CashOnDeliveryCollectionMethodsService } from "../../../services/registry/cashOnDeliveryCollectionMethods";
import { AddressBookService as LogisticAddressBookService } from "../../../services/logistic/addressBook";
import { LocationsService as LogisticLocationsService } from "../../../services/logistic/locations";
import { normalizer as orderNormalizer } from "../utilities/index";
import { normalizer as orderDetailNormalizer } from "../../OrderDetails/utilities/index";
import { callErrorToast, modalDefault, valueIsEmpty } from "../../../utilities";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useTypes } from "../../../utilities/types";

const shipmentsService = new ShipmentsService();
const ordersService = new OrdersService();
const orderDetailsService = new OrderDetailsService();
const cashOnDeliveryCollectionMethodsService =
  new CashOnDeliveryCollectionMethodsService();
const logisticAddressBookService = new LogisticAddressBookService();
const logisticLocationsService = new LogisticLocationsService();
const warehousesService = new WarehousesService();

const HandlerOrderContext = createContext();

const OrderHandlerProvider = ({ children, autosave = false, callback }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const types = useTypes();
  const { accessTokenPayload, dictionary } = useAppContext();

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [newBillingContactEnabled, setNewBillingContactEnabled] =
    useState(false);

  //Passa a true quando non ci sono barcodes da nei dettagli da inserire
  const [createShipmentEnabled, setCreateShipmentEnabled] = useState(false);

  const [order, setOrder] = useState({
    cashOnDeliveryCollectionMethodCode: {
      code: "CASH",
      name: dictionary.options.cash,
    },
    currency: { value: "EUR", label: "Euro" },
  });
  const [orderError, setOrderError] = useState(null);
  const [orderLoader, setOrderLoader] = useState(true);

  const [warehousesError, setWarehousesError] = useState(null);

  const prevOrderError = useRef();
  const prevWarehouseError = useRef();

  const getWarehouses = () => {
    return warehousesService
      .all({ sorting: { selector: "name", order: "asc" } })
      .then((res) => {
        return res.data?.content || [];
      })
      .catch((err) => {
        setWarehousesError(err);
      });
  };

  const getOrder = () => {
    setOrderLoader(true);
    ordersService
      .get(id)
      .then((res) => {
        const order = {
          ...res.data,
          currency: types.currencies2.find(
            (elem) => elem.value === res.data.currency
          ),
        };
        if (order.billing) {
          setNewBillingContactEnabled(order.delivery?.id !== order.billing?.id);
        }

        if (res.data.cashOnDeliveryCollectionMethodCode) {
          cashOnDeliveryCollectionMethodsService
            .get(res.data.cashOnDeliveryCollectionMethodCode)
            .then((res) => {
              setOrder({
                ...order,
                cashOnDeliveryCollectionMethodCode: res.data,
              });
              setOrderLoader(false);
            })
            .catch((err) => setOrderError(err));
          return false;
        }

        setOrder(order);
        setOrderLoader(false);
      })
      .catch((err) => setOrderError(err));
  };

  const createOrder = (save) => {
    setOrderLoader(true);

    const getWarehousesPromise = new Promise((resolve, reject) => {
      resolve(getWarehouses());
    });

    Promise.all([getWarehousesPromise]).then(([warehouses]) => {
      //2 - Viene preso il primo-> se non presente, errore
      const warehouse = warehouses[0];
      if (!warehouse) {
        setOrderError({
          response: {
            status: 400,
            data: {
              warehouse: `${dictionary.words.no_warehouse}.  ${dictionary.orders.impossible_create_order}`,
            },
          },
        });
        setOrderLoader(false);

        return false;
      }

      const orderNormalized = orderNormalizer(
        { ...order, warehouse: warehouse },
        accessTokenPayload
      );

      ordersService
        .create(orderNormalized)
        .then((res) => {
          setOrder({
            ...res.data,
            cashOnDeliveryCollectionMethodCode: {
              code: "CASH",
              name: dictionary.options.cash,
            },
            currency: { value: "EUR", label: "Euro" },
          });
          setOrderLoader(false);

          if (save && callback) {
            callback();
            return;
          }
          navigate(`/orders/edit/${res.data.id}`, { replace: true });
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });
    });
  };

  const cloneOrder = () => {
    setOrderLoader(true);

    ordersService
      .get(id)
      .then((resOrder) => {
        const orderNormalized = orderNormalizer({ ...resOrder.data });
        //Creazione di location
        logisticLocationsService
          .create(orderNormalized.delivery.location)
          .then((resCreateLocation) => {
            orderNormalized.delivery.locationId = resCreateLocation.data.id;
            //Creazione di addressbook
            logisticAddressBookService
              .create(orderNormalized.delivery)
              .then((resCreateAddressbook) => {
                orderNormalized.deliveryId = resCreateAddressbook.data.id;
                //Creazione ordine
                ordersService
                  .create(orderNormalized)
                  .then((resCreateOrder) => {
                    //Recupero dettagli ordine
                    orderDetailsService
                      .all({
                        pagination: { page: 0, size: 200 },
                        parentId: id,
                      })
                      .then((resOrderDetails) => {
                        const promises = [];
                        //Creazione dettagli ordine
                        resOrderDetails.data?.content?.length > 0 &&
                          resOrderDetails.data?.content.forEach((od) => {
                            delete od.barcode;
                            const orderDetailNormalized = orderDetailNormalizer(
                              {
                                ...od,
                              },
                              resCreateOrder.data.id
                            );
                            promises.push(
                              new Promise((resolve) => {
                                resolve(
                                  orderDetailsService.create(
                                    orderDetailNormalized
                                  )
                                );
                              })
                            );
                          });

                        Promise.all([promises]).then(() => {
                          navigate(`/orders/edit/${resCreateOrder.data.id}`, {
                            replace: true,
                          });
                        });
                      })
                      .catch((err) => {
                        setOrderError(err);
                        setOrderLoader(false);
                      });
                  })
                  .catch((err) => {
                    setOrderError(err);
                    setOrderLoader(false);
                  });
              })
              .catch((err) => {
                setOrderError(err);
                setOrderLoader(false);
              });
          })
          .catch((err) => {
            setOrderError(err);
            setOrderLoader(false);
          });
      })
      .catch((err) => setOrderError(err));
  };

  const editOrder = (order, save) => {
    if (save || autosave) {
      const orderNormalized = orderNormalizer({ ...order }, accessTokenPayload);
      ordersService
        .edit(orderNormalized)
        .then((res) => {
          setOrder(order);
          setOrderLoader(false);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });

      return false;
    }
    setOrder(order);
  };

  const updateShipmentNote = (note, save) => {
    if (save || autosave) {
      shipmentsService
        .updateNotes(order.shipments[0].id, note)
        .then((res) => {
          setOrder(order);
          setOrderLoader(false);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });

      return false;
    }
    setOrder(order);
  };

  useEffect(() => {
    let newOrder = { ...order };
    if (order.cashOnDeliveryValue) {
      newOrder.cashOnDeliveryCollectionMethodCode =
        newOrder.cashOnDeliveryCollectionMethodCode || {
          code: "CASH",
          name: dictionary.options.cash,
        };
    } else {
      newOrder.cashOnDeliveryCollectionMethodCode = null;
    }
    setOrder(newOrder);
  }, [order.cashOnDeliveryValue]);

  const removeError = (property) => {
    const newOrderError = { ...orderError };
    delete newOrderError.response?.data[property];
    if (!valueIsEmpty(newOrderError)) {
      prevOrderError.current = newOrderError;
      setOrderError(newOrderError);
    }
  };

  useEffect(() => {
    if (
      warehousesError &&
      JSON.stringify(prevWarehouseError.current) !==
        JSON.stringify(warehousesError)
    ) {
      callErrorToast(warehousesError, dictionary);
    }
  }, [warehousesError]);

  useEffect(() => {
    if (
      orderError &&
      JSON.stringify(prevOrderError.current) !== JSON.stringify(orderError)
    ) {
      callErrorToast(orderError, dictionary);
    }
  }, [orderError]);

  return (
    <HandlerOrderContext.Provider
      value={{
        order,
        orderError,
        orderLoader,
        getOrder,
        createOrder,
        editOrder,
        cloneOrder,
        updateShipmentNote,

        newBillingContactEnabled,
        setNewBillingContactEnabled,
        createShipmentEnabled,
        setCreateShipmentEnabled,

        modal,
        setModal,
        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </HandlerOrderContext.Provider>
  );
};

const useHandlerOrderContext = () => {
  return useContext(HandlerOrderContext);
};

export { OrderHandlerProvider, useHandlerOrderContext };
