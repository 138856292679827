import React from "react";
import { useListContext } from "./Providers/List";
import { useAppContext } from "../../../AppProvider";
import SelectAlbx from "../Selects/Select";

const ElementsPerPage = ({ className }) => {
  const context = useListContext();
  const { dictionary } = useAppContext();

  return (
    context && (
      <div className={className}>
        <span className="span-1 me-1">{dictionary.actions.show}</span>
        <SelectAlbx
          onChange={(e) => {
            context.setPagination({ page: 0, size: Number(e.value) });
          }}
          options={[5, 10, 50].map((elem) => ({ value: elem, label: elem }))}
          value={{
            value: context.pagination.size,
            label: context.pagination.size,
          }}
        />
      </div>
    )
  );
};

export { ElementsPerPage };
