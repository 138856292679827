import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  buildQuerySort,
  buildQuerySearch,
  modalSettingsDefault,
  getUniqueSelectors,
  orderBykey,
  getClassForMultiActionElement,
  writeLabelAddress,
} from "../../utilities";
import {
  allSelectedOrderCanBeDeleted,
  allSelectedOrdersCanBeDeleted,
  canAllOrdersBeDeleted,
  columns,
} from "./utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import useFetch from "../../customHooks/useFetch";

import { Link } from "react-router-dom";
import Delete from "./Delete";
import {
  DeleteOrderProvider,
  DeleteWarehouseProvider,
} from "./providers/Delete";
import { useAppContext } from "../../AppProvider";
import WarehouseArticles from "../WarehouseArticles";
import { IndexWarehouseArticlesProvider } from "../WarehouseArticles/providers/Index";
import { getAllLogistics } from "../../utilities/asyncSelectCallAPI";
import { getLogisticOptionStyle } from "../../Components/common/AsyncSelect/utilities";

const Warehouses = () => {
  const { dictionary, accessTokenPayload } = useAppContext();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [statusOfTableRows, setStatusOfTableRows] = useState([]);

  const appContext = useAppContext();

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/logistic-service/warehouses?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  const onChangeStatusRow = (changeStatusForRows) => {
    setStatusOfTableRows((previousStatusOfTableRows) => {
      changeStatusForRows.forEach((changedRow) => {
        const existingRow = previousStatusOfTableRows.find(
          (row) => row.id === changedRow.id
        );
        if (existingRow) {
          existingRow.value = changedRow.value;
        } else {
          previousStatusOfTableRows.push(changedRow);
        }
      });
      return [...previousStatusOfTableRows];
    });
  };

  const onChangeStatusAllRows = (val) => {
    const newSelectedRow = data.content.map((x) => {
      return { id: x.id, value: val };
    });
    setStatusOfTableRows(newSelectedRow);
  };

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  const allColumns = [
    {
      position: 1,
      Header: dictionary.registries.owner,
      accessor: "owner.name",
      cellClass: "text-nowrap",
      Cell: ({ row, cell }) => {
        if (row.original.ownerId === appContext?.owner?.id) {
          return <span className="badge bg-success">{cell.value}</span>;
        }
        return (
          <span className="badge bg-primary">{dictionary.words.inherited}</span>
        );
      },
      filter: {
        active: true,
        name: "logisticIntegration.ownerId",
        type: "select",
        options: [
          {
            value: appContext?.owner?.id,
            label: appContext?.owner?.name,
          },
        ],
        col: 3,
      },
    },
    {
      position: 2,
      Header: dictionary.words.logistic,
      accessor: "logisticIntegration.logistic.code",
      filter: {
        active: true,
        name: "logisticIntegration.logisticCode",
        type: "async-select",
        loadOptions: (searchValue, pagination) => {
          return getAllLogistics(searchValue, pagination, {
            orderBy: "asc",
            selector: "name",
          });
        },
        multi: true,
        optionStyle: getLogisticOptionStyle,
        col: 3,
      },
      Cell: ({ cell }) => {
        const row = cell.row.original;
        if (row.logisticIntegration?.logistic?.logoUrl) {
          return (
            <img width={50} src={row.logisticIntegration?.logistic?.logoUrl} />
          );
        }
        return row.logisticIntegration?.name || "-";
      },
    },
    {
      position: 3,
      Header: dictionary.registries.name,
      accessor: "name",
    },
    {
      position: 4,
      Header: dictionary.locations.location,
      accessor: "location",
      Cell: ({ cell }) => {
        return cell.row.original.location
          ? writeLabelAddress(cell.row.original.location)
          : "-";
      },
    },
    {
      Header: (
        <>
          <div
            className={
              "multi-action-label scale-animation " +
              getClassForMultiActionElement(statusOfTableRows).labelClass
            }
          >
            {dictionary.words.actions}
          </div>

          <UncontrolledDropdown
            className={
              getClassForMultiActionElement(statusOfTableRows).dropDownClass
            }
          >
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className={"btn border-0 text-body"}
              data-bs-toggle="dropdown"
            >
              <i
                className={
                  "ph-fill ph-list text-primary fw-bolder multi-action-icon scale-animation " +
                  getClassForMultiActionElement(statusOfTableRows).iconClass
                }
              ></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: dictionary.actions.delete_selected,
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    warehouses: [
                      ...data.content
                        .filter((warehouse) =>
                          statusOfTableRows
                            .filter((element) => element.value)
                            .map((x) => x.id)
                            .includes(warehouse.id)
                        )
                        .map((warehouse) => warehouse.id),
                    ],
                    action: "delete",
                  });
                }}
                disabled
              >
                <i className="ph-trash me-2"></i>
                {dictionary.actions.delete_selected}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: original.name,
                    icon: "ph-magnifying-glass",
                    open: !modalSettings.open,
                    action: "articles",
                    warehouse: original.id,
                    size: "xl",
                    className: "bg-color-4",
                  });
                }}
              >
                <i className="ph-magnifying-glass me-2"></i>
                {dictionary.actions.view} {dictionary.products.products}
              </DropdownItem>
              <DropdownItem
                href={`/warehouses/edit/${row.original.id}`}
                disabled={
                  accessTokenPayload.owner !==
                  original.logisticIntegration.ownerId
                }
              >
                <i className="ph-note-pencil me-2"></i>
                {dictionary.actions.edit}
              </DropdownItem>
              <DropdownItem
                className={
                  accessTokenPayload.owner === original.ownerId
                    ? "text-danger"
                    : ""
                }
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: dictionary.actions.delete,
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    action: "delete",
                    warehouses: [original.id],
                  });
                }}
                disabled={accessTokenPayload.owner !== original.ownerId}
              >
                <i className="ph-trash me-2"></i>
                {dictionary.actions.delete}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title={dictionary.registries.warehouses} />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Link
                  to={"/warehouses/create"}
                  className="nav-link float-end"
                  data-key="t-basic-action"
                >
                  <Button
                    color="light"
                    type="button"
                    className="btn btn-outline-success btn-sm"
                  >
                    {dictionary.words.new_sm} {dictionary.registries.warehouse}{" "}
                    <i className="ph-plus ms-2"></i>
                  </Button>
                </Link>
              </div>
            </div>
            <TableAdvanced
              selectMultipleRows={true}
              statusOfTableRows={statusOfTableRows}
              onChangeStatusRow={onChangeStatusRow}
              onChangeStatusAllRows={onChangeStatusAllRows}
              hasFilter={true}
              columns={allColumns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              showLoader={loading}
            />
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        size={modalSettings.size || "md"}
        className={`modal-content`}
      >
        <ModalHeader
          toggle={modalToggle}
          className={`${modalSettings.className}`}
        >
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody className={`${modalSettings.className}`}>
          {modalSettings.action === "delete" && (
            <DeleteWarehouseProvider
              data={modalSettings.warehouses}
              unmountCallback={modalToggle}
            >
              <Delete />
            </DeleteWarehouseProvider>
          )}
          {modalSettings.action === "articles" && (
            <React.Fragment>
              <IndexWarehouseArticlesProvider
                parentId={modalSettings.warehouse}
              >
                <WarehouseArticles action={"details"} shippingRequired={true} />
              </IndexWarehouseArticlesProvider>
              <IndexWarehouseArticlesProvider
                parentId={modalSettings.warehouse}
              >
                <WarehouseArticles
                  action={"details"}
                  shippingRequired={false}
                />
              </IndexWarehouseArticlesProvider>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Warehouses;
