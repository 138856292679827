import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { OrderDetailsService } from "../../../services/logistic/orderDetails";
import { callErrorToast, valueIsEmpty } from "../../../utilities";
import { calculateDimensions, orderDetailNormalizer } from "../utilities";
import { OrdersService } from "../../../services/logistic/orders";
import { dictionary } from "../../../utilities/dictionary";

const orderDetailsService = new OrderDetailsService();
const ordersService = new OrdersService();

const CreateEditOrderDetailContext = createContext();

const CreateEditOrderDetailProvider = ({
  children,
  id,
  autosave = false,
  callback,
  parentId,
  action,
}) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [orderDetail, setOrderDetail] = useState();
  const [orderDetailError, setOrderDetailError] = useState(null);
  const [orderDetailLoader, setOrderDetailLoader] = useState(false);

  const prevArticleRef = useRef(orderDetail?.article);
  const prevQuantityRef = useRef();
  const prevDiscountRef = useRef();
  const prevOrderDetailError = useRef();

  const getOrderDetail = () => {
    setOrderDetailLoader(true);
    orderDetailsService
      .get(id)
      .then((res) => {
        const orderDetail = {
          ...res.data,
          quantity: {
            code: res.data?.quantity || 1,
            name: res.data?.quantity || 1,
          },
        };

        setOrderDetail(orderDetail);
        setOrderDetailLoader(false);
      })
      .catch((err) => {
        setOrderDetailError(err);
      });
  };

  const createEditOrderDetail = (id) => {
    if (!id) {
      createOrderDetail(true);
      return false;
    }
    editOrderDetail(orderDetail, true);
  };

  const createOrderDetail = (save) => {
    setOrderDetailLoader(true);

    const orderDetailNormalized = orderDetailNormalizer(
      { ...orderDetail },
      accessTokenPayload,
      parentId
    );

    if (orderDetailNormalized.article?.uniqueBarcodeItem) {
      const orderDetails = Array.from(
        { length: orderDetailNormalized.quantity },
        (elem) => ({
          ...orderDetailNormalized,
          totalPrice:
            orderDetailNormalized.totalPrice / orderDetailNormalized.quantity,
          totalWeight:
            orderDetailNormalized.totalWeight / orderDetailNormalized.quantity,
          quantity: 1,
        })
      );

      const promises = orderDetails.map((elem) => {
        return orderDetailsService.create(elem);
      });
      Promise.all(promises)
        .then((res) => {
          setOrderDetailLoader(false);
          setOrderDetail({
            ...res.data,
            quantity: {
              code: orderDetailNormalized.quantity || 1,
              name: orderDetailNormalized.quantity || 1,
            },
          });
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderDetailError(err);
          setOrderDetailLoader(false);
        });

      return false;
    }

    orderDetailsService
      .create(orderDetailNormalized)
      .then((res) => {
        setOrderDetail({
          ...res.data,
          quantity: {
            code: res.data?.quantity || 1,
            name: res.data?.quantity || 1,
          },
        });
        setOrderDetailLoader(false);

        if (save && callback) {
          callback();
        }
      })
      .catch((err) => {
        setOrderDetailError(err);
        setOrderDetailLoader(false);
      });
  };

  const editOrderDetail = (orderDetail, save) => {
    if (save || autosave) {
      const orderDetailNormalized = orderDetailNormalizer(
        { ...orderDetail },
        accessTokenPayload,
        parentId
      );
      orderDetailsService
        .edit(orderDetailNormalized)
        .then((res) => {
          setOrderDetail({ ...orderDetail });
          setOrderDetailLoader(false);
          if (save && callback) {
            callback(true);
          }
        })
        .catch((err) => {
          setOrderDetailError(err);
          setOrderDetailLoader(false);
        });

      return false;
    }

    setOrderDetail({ ...orderDetail });
  };

  const removeError = (property) => {
    const newOrderDetailError = { ...orderDetailError };
    delete newOrderDetailError.response?.data[property];
    if (!valueIsEmpty(newOrderDetailError)) {
      prevOrderDetailError.current = newOrderDetailError;
      setOrderDetailError(newOrderDetailError);
    }
  };

  useEffect(() => {
    // Se previousArticleRef.current è diverso dal prodotto corrente, è un vero cambiamento
    if (prevArticleRef.current !== orderDetail?.article) {
      // Ignora il primo caricamento se prevArticleRef.current è undefined o null
      if (
        prevArticleRef.current !== undefined ||
        prevArticleRef.current !== null
      ) {
        const newOrderDetail = calculateDimensions(orderDetail);
        editOrderDetail(newOrderDetail);
      }
      // Aggiorna il valore precedente con il valore corrente
      prevArticleRef.current = orderDetail?.article;
    }
  }, [orderDetail?.article]);

  useEffect(() => {
    if (prevQuantityRef.current !== orderDetail?.quantity.code) {
      if (
        prevQuantityRef.current !== undefined ||
        prevQuantityRef.current !== null
      ) {
        const newOrderDetail = calculateDimensions(orderDetail);
        editOrderDetail(newOrderDetail);
      }
      prevQuantityRef.current = orderDetail?.quantity;
    }
  }, [orderDetail?.quantity]);

  useEffect(() => {
    if (prevDiscountRef.current !== orderDetail?.discount) {
      if (
        prevDiscountRef.current !== undefined ||
        prevDiscountRef.current !== null
      ) {
        const newOrderDetail = calculateDimensions(orderDetail);
        editOrderDetail(newOrderDetail);
      }
      prevDiscountRef.current = orderDetail?.quantity;
    }
  }, [orderDetail?.discount]);

  useEffect(() => {
    if (
      orderDetailError &&
      JSON.stringify(prevOrderDetailError.current) !==
        JSON.stringify(orderDetailError)
    ) {
      callErrorToast(orderDetailError, dictionary);
    }
  }, [orderDetailError]);

  useEffect(() => {
    if (!id) {
      setOrderDetail({ quantity: { code: 1, name: 1 } });
    }
  }, []);

  return (
    <CreateEditOrderDetailContext.Provider
      value={{
        id,

        orderDetail,
        orderDetailError,
        orderDetailLoader,
        getOrderDetail,
        createOrderDetail,
        editOrderDetail,
        createEditOrderDetail,

        removeError,
        callback,
        autosave,
        action,
      }}
    >
      {children}
    </CreateEditOrderDetailContext.Provider>
  );
};

const useCreateEditOrderDetailContext = () => {
  return useContext(CreateEditOrderDetailContext);
};

export { CreateEditOrderDetailProvider, useCreateEditOrderDetailContext };
