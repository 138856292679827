import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { convertFromJson } from "fiql-query-builder";
import { useOidcUser } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Delete from "./delete";
import Share from "./share";
import Relate from "./relate";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";

import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import {
  getShipmentLabel,
  getContractTypeLabel,
  getShipmentTypeBadge,
  getParcelTypeBadge,
  getUniqueSelectors,
  stringToColor,
  callErrorToast,
} from "../../utilities";
import { ContractServicesService } from "../../services/contract-service/contractServices";
import { ContractService } from "../../services/contract-service/contract";
import { useAppContext } from "../../AppProvider";

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const contractsService = new ContractService();
const contractServicesService = new ContractServicesService();

const ContractServices = () => {
  const query = useQuery();
  const { oidcUser } = useOidcUser();
  const [contract, setContract] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [queryParams, setQueryParams] = useState([
    {
      equals: {
        selector: "contract.id",
        args: query.get("contractId"),
      },
    },
  ]);
  const [data, setData] = useState({});

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    sort: "desc",
  });

  const { dictionary } = useAppContext();

  const getContract = () => {
    contractsService
      .getContract(query.get("contractId"))
      .then((response) => {
        setContract(response);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleContractServiceState = (
    contractServiceId,
    contractServiceState
  ) => {
    contractServicesService
      .editContractServiceState(contractServiceId, !contractServiceState)
      .then((response) => {
        fetchData();
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const columns = [
    {
      Header: dictionary.words.active,
      Cell: ({ row }) => {
        if (oidcUser.owner === row.original.ownerId) {
          return (
            <div className="form-check form-switch">
              <input
                readOnly
                onClick={() =>
                  handleContractServiceState(
                    row.original.id,
                    row.original.active
                  )
                }
                checked={row.original.active}
                type="radio"
                className="form-check-input form-check-input-info cursor-pointer"
              />
            </div>
          );
        }
        return (
          <div className="form-check form-switch disabled">
            <input
              disabled
              checked={row.original.active}
              type="radio"
              className="form-check-input form-check-input-info cursor-pointer"
            />
          </div>
        );
      },
    },
    {
      Header: dictionary.registries.carrier,
      accessor: "carrierService.carrier.name",
    },
    {
      Header: dictionary.services.service,
      accessor: "carrierService.name",
      Cell: ({ cell }) => {
        const row = cell.row.original;
        return (
          <span
            style={{
              backgroundColor: stringToColor(row.carrierServiceCode),
            }}
            className="badge"
          >
            {row.carrierService.name}
          </span>
        );
      },
    },
    {
      Header: dictionary.shipments.shipment_typology,
      accessor: "carrierService.shipmentType",
      Cell: ({ row }) => {
        return getShipmentTypeBadge(row.original.carrierService.shipmentType);
      },
    },
    {
      Header: dictionary.parcels.package_type_2,
      accessor: "parcelTypeCode",
      Cell: ({ row }) => {
        return getParcelTypeBadge(row.original.parcelType);
      },
    },
    {
      Header: dictionary.registries.name,
      accessor: "name",
      sortable: false,
      filter: {
        active: true,
        name: "name",
        type: "search",
      },
    },
    {
      Header: dictionary.registries.description,
      accessor: "description",
      filter: {
        active: true,
        name: "description",
        type: "search",
      },
    },
    {
      Header: dictionary.references.external_reference,
      accessor: "externalReference",
    },
    {
      Header: dictionary.words.actions,
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  href={`/contract-services/edit/${row.original.id}`}
                >
                  <i className="ph-note-pencil me-2"></i>
                  {dictionary.actions.edit}
                </DropdownItem>
              )}
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  href={`/contract-services/clone/${row.original.id}`}
                >
                  <i className="ph-users me-2"></i>
                  {dictionary.actions.duplicate}
                </DropdownItem>
              )}
              {oidcUser?.owner !== original.ownerId && (
                <DropdownItem
                  disabled
                  href={`/contract-services/details/${row.original.id}`}
                >
                  <i className="ph-magnifying-glass me-2"></i>
                  {dictionary.actions.details}
                </DropdownItem>
              )}
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  hidden={contract?.type !== "ACTIVE"}
                  href="#"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title:
                        dictionary.services.assignment_of_service_to_customers,
                      icon: "ph-users-three",
                      open: !modalSettings.open,
                      action: "share",
                      id: original.id,
                      size: "lg",
                    });
                  }}
                >
                  <i className="ph-users-three me-2"></i>
                  {dictionary.actions.assign}
                </DropdownItem>
              )}
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  hidden={contract?.type !== "ACTIVE"}
                  href="#"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: dictionary.actions.relate,
                      icon: "ph-link-simple-horizontal",
                      open: !modalSettings.open,
                      action: "relate",
                      id: original.id,
                      parcelType: original.parcelType,
                      size: "lg",
                    });
                  }}
                >
                  <i className="ph-link-simple-horizontal me-2"></i>
                  {dictionary.actions.relate}
                </DropdownItem>
              )}
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  className="text-danger"
                  href="#"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: dictionary.actions.delete,
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      action: "delete",
                      id: [original.id],
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  {dictionary.actions.delete}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const fetchData = (
    filters = {
      pagination: { ...pagination },
      search: buildQuerySearch(queryParams),
    }
  ) => {
    setShowLoader(true);
    contractServicesService
      .getAll(filters)
      .then((response) => {
        setData(response);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    if (oidcUser) {
      fetchData();
    }
  }, [queryParams, pagination, oidcUser]);

  useEffect(() => {
    if (query.get("contractId")) {
      getContract();
    }
  }, []);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title={dictionary.services.services}
          breadcrumbItem={
            contract.name
              ? `${dictionary.services.contract_services} ${contract.name}`
              : dictionary.services.services
          }
          link={`/contract-services?contractId=${query.get("contractId")}`}
        />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Link
                  to={`/contract-services/create?contractId=${query.get(
                    "contractId"
                  )}`}
                  className="nav-link float-end"
                  data-key="t-basic-action"
                >
                  <Button
                    color="light"
                    type="button"
                    className="btn btn-outline-success btn-sm"
                  >
                    {dictionary.actions.create_new}{" "}
                    <i className="ph-plus ms-2"></i>
                  </Button>
                </Link>
              </div>
            </div>

            {oidcUser && contract.id && (
              <TableAdvanced
                hasFilter={true}
                columns={columns}
                data={data}
                className="custom-header-css"
                tableClass={"table-xxs"}
                paginationOpt={pagination}
                onPaginationChange={handlePagination}
                refreshTable={fetchData}
                onSearch={onSearch}
                onFreeSearch={onSearch}
                showLoader={showLoader}
              />
            )}

            <Modal
              fade
              isOpen={modalSettings.open}
              className="modal-content"
              direction="end"
              size={modalSettings.size || "md"}
            >
              <ModalHeader toggle={modalToggle}>
                <span className="modal-title">
                  <i className={modalSettings.icon + " me-2"}></i>
                  {modalSettings.title}
                </span>
              </ModalHeader>
              <ModalBody>
                {modalSettings.action === "delete" && (
                  <Delete
                    contractServiceId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={fetchData}
                  />
                )}
                {modalSettings.action === "share" && (
                  <Share
                    contractServiceId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={fetchData}
                  />
                )}
                {modalSettings.action === "relate" && (
                  <Relate
                    contractServiceId={modalSettings.id}
                    parcelType={modalSettings.parcelType}
                    modalToogle={modalToggle}
                    refreshTable={fetchData}
                    contract={contract}
                  />
                )}
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ContractServices;
