import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faInfoCircle,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { CreateEdit } from "./CreateEdit";
import { CreateEditWarehouseArticleProvider } from "./providers/CreateEdit";
import { DeleteWarehouseArticleProvider } from "./providers/Delete";
import Delete from "./Delete";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useIndexWarehouseArticlesContext } from "./providers/Index";
import moment from "moment";

const WarehouseArticles = ({ action, shippingRequired = true }) => {
  const listRef = useRef(null);
  const listProps = useListProps(action, shippingRequired);

  const context = useIndexWarehouseArticlesContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} action={action} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        parentId={context?.parentId}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          renderItem={renderItem}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
        />
      </ListProvider>

      <Modal isOpen={context?.modal.open} size={context?.modal.size}>
        <ModalHeader
          toggle={() => {
            listRef?.current.refresh();
            context?.setModal({ open: false });
          }}
        >
          <span className="modal-title">
            <i className={context?.modal.icon + " me-2"}></i>
            {context?.modal.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {context?.modal.action === "create" && (
            <CreateEditWarehouseArticleProvider
              unmountCallback={() => {
                listRef?.current.refresh();
                context?.setModal({ open: false });
              }}
            >
              <CreateEdit
                parentId={context?.parentId}
                unmountCallback={() => {
                  listRef?.current.refresh();
                  context?.setModal({ open: false });
                }}
              />
            </CreateEditWarehouseArticleProvider>
          )}
          {context?.modal.action === "edit" && (
            <CreateEditWarehouseArticleProvider
              unmountCallback={() => {
                listRef?.current.refresh();
                context?.setModal({ open: false });
              }}
            >
              <CreateEdit
                parentId={context?.parentId}
                id={context?.modal.data}
                unmountCallback={() => {
                  listRef?.current.refresh();
                  context?.setModal({ open: false });
                }}
              />
            </CreateEditWarehouseArticleProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteWarehouseArticleProvider
              data={context?.modal.data}
              unmountCallback={() => {
                listRef?.current.refresh();
                context?.setModal({ open: false });
              }}
            >
              <Delete />
            </DeleteWarehouseArticleProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default WarehouseArticles;

const ListItem = React.memo(({ item, index, action }) => {
  const { dictionary } = useAppContext();

  const context = useIndexWarehouseArticlesContext();

  return (
    <React.Fragment key={index}>
      <div className="list-item px-1">
        {item.article.shippingRequired ? (
          <React.Fragment>
            <div
              className="d-grid grid-column text-center mb-1"
              style={{
                gridTemplateColumns: `auto`,
              }}
            >
              <div className="bg-color-4">
                <div>
                  <label className="label-3">{item.article.name}</label>
                </div>
              </div>
            </div>
            <div
              className="d-grid grid-column text-center"
              style={{
                gridTemplateColumns: `auto`,
              }}
            >
              <div className="d-flex flex-column justify-content-between">
                <div className="bg-color-4">
                  <div>
                    <label className="label-1">
                      {dictionary.status.coming_soon}
                    </label>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary.status.processing}
                      </label>
                    </div>
                    <div>
                      <label className="label-2">
                        {dictionary.status.completed_pm}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-2">
                        {dictionary.words.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-3">
                        {item.incomingInfo.processing}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.incomingInfo.completed}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-3">
                        {item.incomingInfo.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-3"> </label>
                    </div>
                    <div>
                      <label className="label-3"> </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-3"> &nbsp;</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <div>
                  <div>
                    <label className="label-1 mb-1">
                      {dictionary.words.estimated_pm}
                    </label>
                  </div>
                </div>
                <div>
                  <div>
                    <label
                      className="label-1 text-decoration-underline cursor-pointer"
                      id={"effective_" + item.id}
                    >
                      {dictionary.words.effective_pm}{" "}
                    </label>
                    <UncontrolledTooltip
                      placement="right"
                      target={"effective_" + item.id}
                    >
                      {dictionary.dates.last_update_date}:{" "}
                      {moment(
                        item.warehouseInfo.effectiveInfo.lastEffectiveDate
                      ).format("DD/MM/YYYY HH:mm:ss")}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column bg-color-4"
                  style={{
                    gridTemplateColumns: `1fr`,
                  }}
                >
                  <div>
                    <label className="label-1">
                      {dictionary.status.in_stock}
                    </label>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary.status.committed_pm}
                      </label>
                    </div>
                    <div>
                      <label
                        className="label-2 text-decoration-underline cursor-pointer"
                        id={"blocked_" + item.id}
                      >
                        {dictionary.status.blocked_pm}{" "}
                      </label>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"blocked_" + item.id}
                      >
                        {dictionary.status.not_available_for_sale}
                      </UncontrolledTooltip>
                    </div>
                    <div>
                      <label className="label-2">
                        {dictionary.status.availables}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-2">
                        {dictionary.words.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-3">
                        {item.warehouseInfo.expectedInfo.committed}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.warehouseInfo.expectedInfo.blocked}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.warehouseInfo.expectedInfo.available}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-3">
                        {item.warehouseInfo.expectedInfo.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
                    }}
                  >
                    <div></div>
                    <div>
                      <label className="label-3">
                        {item.warehouseInfo.effectiveInfo.blocked}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.warehouseInfo.effectiveInfo.available}
                      </label>
                    </div>
                    <div className="bg-color-4"></div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-color-4">
                  <div>
                    <label className="label-1">
                      {dictionary.status.sold_pm}
                    </label>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary.status.lost_pm}
                      </label>
                    </div>
                    <div>
                      <label
                        id={"out_of_delivery_" + item.id}
                        className="label-2 text-decoration-underline cursor-pointer"
                      >
                        {dictionary.status.out_of_delivery}
                      </label>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"out_of_delivery_" + item.id}
                      >
                        {dictionary.status.out_of_delivery}{" "}
                        {dictionary.registries.at_customer}
                      </UncontrolledTooltip>
                    </div>
                    <div>
                      <label
                        id={"delivered_" + item.id}
                        className="label-2 text-decoration-underline cursor-pointer"
                      >
                        {dictionary.status.delivered_pm}{" "}
                      </label>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"delivered_" + item.id}
                      >
                        {dictionary.status.delivered_pm}{" "}
                        {dictionary.registries.at_customer}
                      </UncontrolledTooltip>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-2">
                        {dictionary.words.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-3">{item.soldInfo.lost}</label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.soldInfo.onDelivery}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.soldInfo.delivered}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-3">{item.soldInfo.total}</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                    }}
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="bg-color-4">
                      <label> </label>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <div>
                  <div>
                    <label className="label-1 mb-1">
                      {dictionary.words.new}
                    </label>
                  </div>
                </div>
                <div>
                  <div>
                    <label className="label-1">
                      {dictionary.words.used_pm}{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="bg-color-4">
                  <div>
                    <label className="label-1">
                      {dictionary.status.outgoing}
                    </label>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary.status.processing}
                      </label>
                    </div>
                    <div>
                      <label className="label-2">
                        {dictionary.status.completed_pm}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-2">
                        {dictionary.words.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-3">
                        {item.outgoingInfo.newInfo.processing}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.outgoingInfo.newInfo.completed}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-3">
                        {item.outgoingInfo.newInfo.total}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr 0.5fr`,
                    }}
                  >
                    <div>
                      <label className="label-3">
                        {item.outgoingInfo.usedInfo.processing}
                      </label>
                    </div>
                    <div>
                      <label className="label-3">
                        {item.outgoingInfo.usedInfo.completed}
                      </label>
                    </div>
                    <div className="bg-color-4 text-center">
                      <label className="label-3">
                        {item.outgoingInfo.usedInfo.total}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                {action !== "details" && (
                  <React.Fragment>
                    <ButtonUtility
                      className="ms-1"
                      title={dictionary.actions.edit}
                      onClick={() => {
                        context?.setModal({
                          open: true,
                          title: `${dictionary.products.edit_product}`,
                          action: "edit",
                          data: item.id,
                          size: "md",
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </ButtonUtility>
                    <ButtonDelete
                      className="ms-1"
                      onClick={() => {
                        context?.setModal({
                          open: true,
                          title: `${dictionary.products.delete_product}`,
                          action: "delete",
                          data: [item.id],
                          size: "sm",
                        });
                      }}
                    >
                      {" "}
                    </ButtonDelete>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div
            className="d-grid grid-column text-center"
            style={{
              gridTemplateColumns: `4fr 7fr auto`,
            }}
          >
            <div>
              <div
                className="d-grid grid-column bg-color-4"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-1">{dictionary.registries.name}</label>
              </div>
              <div
                className="d-grid grid-column"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-3">{item.article.name}</label>
              </div>
            </div>
            <div>
              <div className="bg-color-4">
                <div>
                  <label className="label-1">{dictionary.status.sold_pm}</label>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.status.lost_pm}
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.status.out_of_delivery}{" "}
                      <FontAwesomeIcon
                        id={"out_of_delivery_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"out_of_delivery_" + item.id}
                      >
                        {dictionary.status.out_of_delivery}{" "}
                        {dictionary.registries.at_customer}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.status.delivered_pm}{" "}
                      <FontAwesomeIcon
                        id={"delivered_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"delivered_" + item.id}
                      >
                        {dictionary.status.delivered_pm}{" "}
                        {dictionary.registries.at_customer}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-2">{dictionary.words.total}</label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-3">{item.soldInfo.lost}</label>
                  </div>
                  <div>
                    <label className="label-3">
                      {item.soldInfo.onDelivery}
                    </label>
                  </div>
                  <div>
                    <label className="label-3">{item.soldInfo.delivered}</label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-3">{item.soldInfo.total}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {action !== "details" && (
                <React.Fragment>
                  <ButtonUtility
                    className="ms-1"
                    title={dictionary.actions.edit}
                    onClick={() => {
                      context?.setModal({
                        open: true,
                        title: `${dictionary.products.edit_product}`,
                        action: "edit",
                        data: item.id,
                        size: "md",
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ButtonUtility>
                  <ButtonDelete
                    className="ms-1"
                    onClick={() => {
                      context?.setModal({
                        open: true,
                        title: `${dictionary.products.delete_product}`,
                        action: "delete",
                        data: [item.id],
                        size: "sm",
                      });
                    }}
                  >
                    {" "}
                  </ButtonDelete>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});
