import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { DeleteArticleContext } from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";

const Delete = () => {
  const { dictionary } = useAppContext();

  const {
    articles,
    articlesError,
    articlesLoader,
    deleteArticles,
    unmountCallback,
  } = useContext(DeleteArticleContext);

  useEffect(() => {
    if (articlesError) {
      callErrorToast(articlesError, dictionary);
    }
  }, [articlesError]);

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {articles?.length > 1
              ? dictionary.messages.ask_to_delete_selected
              : dictionary.messages.ask_to_delete}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (unmountCallback) {
              unmountCallback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={deleteArticles}
          disabled={articlesLoader}
        >
          {articlesLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            dictionary.actions.delete
          )}
        </Button>
      </div>
    </form>
  );
};

export default Delete;
