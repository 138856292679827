import React from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { useReturnSenderOrderContext } from "./providers/ReturnSender";

const ReturnSender = () => {
  const { dictionary } = useAppContext();

  const context = useReturnSenderOrderContext();

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary.actions.confirm_status}:{" "}
            {dictionary.status.return_sender}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-5 text-center">
            {context.orders?.length > 1
              ? `${dictionary.messages.ask_confirm_confirmation} ${
                  context.orders?.length
                } ${dictionary.words.items.toLowerCase()}?`
              : `${dictionary.messages.ask_confirm_confirmation}?`}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={() => context.returnSenderOrders()}
          disabled={context.ordersLoader}
          loading={context.ordersLoader}
        />
      </div>
    </React.Fragment>
  );
};

export default ReturnSender;
