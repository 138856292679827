import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import moment from "moment";

import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { TooltipCopy } from "../../Components/common/Tooltips/TooltipCopy";

const CODCollections = () => {
  const listRef = useRef(null);
  const listProps = useListProps();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
        />
      </ListProvider>
    </React.Fragment>
  );
};

export default CODCollections;

const ListItem = React.memo(({ item, index }) => {
  const { dictionary } = useAppContext();

  return (
    <React.Fragment key={index}>
      <div
        className="list-item d-grid align-items-center px-2"
        style={{
          gridAutoFlow: "column",
          gridTemplateColumns: `1fr 2fr 0.75fr 0.75fr 1fr 1fr 0.5fr 1.5fr`,
          gap: "0.5rem",
        }}
      >
        <div>
          <label className="label-2">{dictionary.dates.creation_date}:</label>
          <label className="label-3">
            {moment(item.creationDate).format("DD/MM/YYYY")}
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.references.external_reference}:
          </label>
          <TooltipCopy
            target={"externalReference_" + index}
            textToCopy={item.externalReference}
          >
            <label
              className={`label-3 ${
                item.externalReference ? "cursor-pointer" : ""
              }`}
            >
              {item.externalReference || "-"}
            </label>
          </TooltipCopy>
        </div>
        <div className="text-center">
          <label className="label-2">{dictionary.registries.supplier}:</label>
          {item.supplierLogoUrl && (
            <img src={item.supplierLogoUrl} title={item.supplierName} />
          )}
        </div>
        <div className="text-center">
          <label className="label-2">{dictionary.registries.customer}:</label>
          {item.customerLogoUrl && (
            <img src={item.customerLogoUrl} title={item.customerName} />
          )}
        </div>
        <div>
          <label className="label-2">
            {dictionary.dimensions.expected_amount}:
          </label>
          <label className="label-3">
            {item.expectedAmount ? `${item.expectedAmount.toFixed(2)} €` : "-"}
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.dimensions.collected_amount}:
          </label>
          <label className="label-3">
            {item.collectedAmount
              ? `${item.collectedAmount.toFixed(2)} €`
              : "-"}
          </label>
        </div>

        <div>
          <label className="label-2">{dictionary.words.delta}:</label>
          <label
            className={`label-3 ${
              item.collectedAmount - item.expectedAmount >= 0
                ? "text-success"
                : "text-danger"
            }`}
          >
            {item.collectedAmount && item.expectedAmount
              ? `${(item.collectedAmount - item.expectedAmount).toFixed(2)} €`
              : "-"}
          </label>
        </div>
        <div>
          <label className="label-2">{dictionary.dates.collection_date}:</label>
          <label className="label-3">
            {item.collectionDate
              ? moment(item.collectionDate).format("DD/MM/YYYY")
              : "-"}
          </label>
        </div>
        {/* <div>
          <Link
            className="button-1 button-1-light button-1-icon"
            title={dictionary["download"][language]}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Link>
        </div> */}
        <div>
          <Link
            target="_blank"
            className="button-1 button-1-light button-1-icon"
            to={`/cod-collections/details/${item.id}`}
            title={dictionary.actions.details}
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
});
