import { orderBykey, valueIsEmpty } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";
import { CarriersService } from "../../services/registry/carriers";
import { useAppContext } from "../../AppProvider";

const carrierService = new CarriersService();

//#region Defaults
export const carrierIntegrationDefault = {
  carrierCode: null,
  name: null,
  ownerId: null,
  credentials: [],
};
//#endregion

//#region Utilities
export const carrierIntegrationNormalizer = (carrierIntegration, ownerId) => {
  carrierIntegration.ownerId = ownerId;
  carrierIntegration.carrierCode = carrierIntegration.carrierCode.value;
  return carrierIntegration;
};

export const carrierIntegrationValidation = (carrierIntegration) => {
  const { dictionary } = useAppContext();
  let errors = {};
  var { name, carrierCode } = carrierIntegration;

  if (valueIsEmpty(name)) errors["name"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(carrierCode))
    errors["carrierCode"] = dictionary.messages.mandatory_field;

  return errors;
};

export const manipulatesCredentialObjects = (array, name, value) => {
  const credentialIndex = array.findIndex((elem) => elem.rank === name);

  if (credentialIndex !== -1) {
    if (value === "") {
      // Rimuovi l'oggetto dall'array
      array.splice(credentialIndex, 1);
    } else {
      // Aggiorna il valore della proprietà "value"
      array[credentialIndex].value = value;
    }
  } else {
    array.push({ rank: name, value: value });
  }

  return orderBykey(array, "rank");
};
//#endregion

//#region API Calls
export const getCarrier = (code) => {
  return new Promise((resolve, reject) => {
    carrierService
      .get(code)
      .then((response) => {
        resolve({
          value: response.data.code,
          label: response.data.name,
          logoUrl: response.data.logoUrl,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCarrierCredentialLabels = (code) => {
  return new Promise((resolve, reject) => {
    carrierService
      .getCredentialLabels(code)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion
