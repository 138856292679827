import React, { createContext, useContext, useEffect, useState } from "react";

import {
  callErrorToast,
  callSuccessToast,
  modalDefault,
} from "../../../utilities";
import { OrdersService } from "../../../services/logistic/orders";
import { useAppContext } from "../../../AppProvider";
import { faChevronDoubleRight } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useHandlerOrderContext } from "../../Orders/providers/Handler";

const IndexOrderDetailsContext = createContext();

const ordersService = new OrdersService();

const IndexOrderDetailsProvider = ({ children, parentId, callback }) => {
  const { dictionary, accessTokenPayload } = useAppContext();

  const handlerOrderContext = useHandlerOrderContext();

  const [orderDetail, setOrderDetail] = useState();
  const [orderDetailError, setOrderDetailError] = useState(null);
  const [orderDetailLoader, setOrderDetailLoader] = useState(false);

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const nextDetail = () => {
    setOrderDetailLoader(true);
    ordersService
      .nextDetail(parentId)
      .then((res) => {
        setOrderDetailLoader(false);
        if (res.status === 200) {
          setModal({
            ...modal,
            title: `${
              dictionary.actions.complete
            } ${dictionary.products.product.toLowerCase()}`,
            icon: faChevronDoubleRight,
            open: true,
            action: "nextDetail",
            id: res.data,
          });
          return;
        }
        handlerOrderContext?.setCreateShipmentEnabled(true);
        callSuccessToast(
          {
            ...res,
            message: dictionary.products.all_products_are_completed,
          },
          dictionary
        );
      })
      .catch((err) => {
        setOrderDetailLoader(false);
        setOrderDetailError(err);
      });
  };

  useEffect(() => {
    if (orderDetailError) {
      callErrorToast(orderDetailError, dictionary);
    }
  }, [orderDetailError]);

  useEffect(() => {
    if (
      handlerOrderContext.order.lastStatus === "PROCESSING" &&
      accessTokenPayload.owner === "7f8625b9-973b-4c66-80af-346b49cde2e7"
    ) {
      //allabox senders
      nextDetail();
    }
  }, [handlerOrderContext.order.lastStatus]);

  return (
    <IndexOrderDetailsContext.Provider
      value={{
        orderDetail,
        orderDetailError,
        orderDetailLoader,
        nextDetail,

        callback,

        parentId,
        modal,
        setModal,
      }}
    >
      {children}
    </IndexOrderDetailsContext.Provider>
  );
};

const useIndexOrderDetailsContext = () => {
  return useContext(IndexOrderDetailsContext);
};

export { IndexOrderDetailsProvider, useIndexOrderDetailsContext };
