import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Button } from "reactstrap";
import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import { ListBox } from "primereact/listbox";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "../../assets/scss/internal/listbox-multiple.scss";

import { ContractServicesService } from "../../services/contract-service/contractServices";
import CompaniesService from "../../services/registry-service/companies";
import {
  callErrorToast,
  getCompanyBadge,
  getCompanyLabel,
  getParcelTypeBadge,
} from "../../utilities";
import img from "../../assets/images/no-image-available.png";
import { useAppContext } from "../../AppProvider";

const contractServicesService = new ContractServicesService();
const companiesService = new CompaniesService();

const Share = ({ contractServiceId, modalToogle, refreshTable }) => {
  const { oidcUser } = useOidcUser();

  //Tutti i clienti assegnabili ricevuti dal BE
  const [allSourceOptions, setAllSourceOptions] = useState([]);
  //Clienti assegnabili
  const [source, setSource] = useState([]);
  //Clienti assegnati
  const [target, setTarget] = useState([]);
  //Clienti assegnabili selezionati nella listbox
  const [sourceOptionsSelected, setSourceOptionsSelected] = useState([]);
  //Clienti assegnati selezionati nella listbox
  const [targetOptionsSelected, setTargetOptionsSelected] = useState([]);

  const { dictionary } = useAppContext();

  const getCompanies = (inputValue = "") => {
    const filters = {
      search: { ownerId: oidcUser.owner },
      sort: {
        property: "name",
        mode: "asc,ignorecase",
      },
      freeSearch: inputValue,
      url: `/registry-service/companies?page=${0}&size=${1000}&search=id%21%3D${
        oidcUser.owner
      }`,
    };

    companiesService
      .getAll(filters)
      .then((response) => {
        setAllSourceOptions(response.content || []);
        getCompaniesInContractService(response.content || []);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getCompaniesInContractService = (companies) => {
    contractServicesService
      .getCompaniesInContractService(contractServiceId)
      .then((response) => {
        if (response.length) {
          // Filtra gli elementi di companies che non hanno un corrispondente id in response
          const filteredCompanies = companies.filter(
            (company) =>
              !response.some((responseOpt) => responseOpt.id === company.id)
          );

          setSource(filteredCompanies);
          setTarget(response);
          return false;
        }

        setSource(companies);
        setTarget([]);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const deleteCompaniesInContractService = () => {
    contractServicesService
      .deleteCompaniesInContractService(contractServiceId)
      .then((response) => {
        if (target.length > 0) {
          createCompaniesInContractService();
          return false;
        }
        refreshTable();
        modalToogle();
        toast.success(dictionary.messages.save_successful);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const createCompaniesInContractService = () => {
    const targetCounter = target.length;
    target.forEach((elem, index) => {
      contractServicesService
        .createCompaniesInContractService(contractServiceId, elem.id)
        .then((response) => {
          if (index === targetCounter - 1) {
            refreshTable();
            modalToogle();
            toast.success(dictionary.messages.save_successful);
          }
        })
        .catch((err) => {
          callErrorToast(err, dictionary);
        });
    });
  };

  const moveAllOptionsFromSourceToTarget = () => {
    setSource([]);
    setTarget(allSourceOptions);
  };

  const moveAllOptionsFromTargetToSource = () => {
    setSource(allSourceOptions);
    setTarget([]);
  };

  const moveSelectedOptionsFromSourceToTarget = () => {
    if (sourceOptionsSelected.length > 0) {
      const newSource = [];

      source.forEach((elem) => {
        sourceOptionsSelected.forEach((option) => {
          if (option.id !== elem.id) {
            newSource.push(elem);
          }
        });
      });

      setSource(newSource);
      setTarget([...target, ...sourceOptionsSelected]);
      setSourceOptionsSelected([]);
    }
  };

  const moveSelectedOptionsFromTargetToSource = () => {
    if (targetOptionsSelected.length > 0) {
      const newTarget = [];

      target.forEach((elem) => {
        targetOptionsSelected.forEach((option) => {
          if (option.id !== elem.id) {
            newTarget.push(elem);
          }
        });
      });

      setSource([...source, ...targetOptionsSelected]);
      setTarget(newTarget);
      setTargetOptionsSelected([]);
    }
  };

  const itemTemplate = (option) => {
    return (
      <div className="border-bottom pb-2">
        <div className="row">
          <div className="col">
            <img width={40} src={option.logoDarkUrl || img} />{" "}
            <label className="d-inline">{option.name}</label>
            <span style={{ fontSize: "10px" }}>
              {option.externalReference && ` (${option.externalReference})`}
            </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col opacity-50">{getCompanyBadge(option.type)}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (oidcUser) {
      getCompanies();
    }
  }, [oidcUser]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          {/* <p>
            <i className="ph ph-info me-1"></i>Selezionare i clienti desiderati
            e cliccare su una delle frecce azione per spostarli nell'altra
            colonna.
          </p> */}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row align-items-center">
            <div className="col-5">
              <h5>{dictionary.words.all_pm}</h5>
              <ListBox
                value={sourceOptionsSelected}
                onChange={(e) => setSourceOptionsSelected(e.value)}
                options={source}
                optionLabel="label"
                itemTemplate={itemTemplate}
                className="w-full md:w-14rem"
                listStyle={{ height: "350px" }}
                multiple
                filter
                filterBy="name,externalReference"
                emptyFilterMessage={() => (
                  <label className="text-center d-block">
                    {dictionary.messages.no_results_found}
                  </label>
                )}
                emptyMessage={() => {
                  <label className="text-center d-block">
                    {dictionary.services.all_services_are_related}
                  </label>;
                }}
                onDoubleClick={moveSelectedOptionsFromSourceToTarget}
              />
            </div>
            <div className="col-2">
              <div className="row">
                <button
                  title={dictionary.messages.move_all_items_to_the_right}
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveAllOptionsFromSourceToTarget}
                >
                  <i className="ph-caret-double-right"></i>
                </button>
              </div>
              <div className="row mt-2">
                <button
                  title={dictionary.messages.move_selected_items_to_the_right}
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveSelectedOptionsFromSourceToTarget}
                >
                  <i className="ph-caret-right"></i>
                </button>
              </div>
              <div className="row mt-2">
                <button
                  title={dictionary.messages.move_all_items_to_the_left}
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveSelectedOptionsFromTargetToSource}
                >
                  <i className="ph-caret-left"></i>
                </button>
              </div>
              <div className="row mt-2">
                <button
                  title={dictionary.messages.move_selected_items_to_the_left}
                  type="button"
                  className="btn btn-outline-light p-2 d-inline-block"
                  onClick={moveAllOptionsFromTargetToSource}
                >
                  <i className="ph-caret-double-left"></i>
                </button>
              </div>
            </div>
            <div className="col-5">
              <h5>{dictionary.words.selected_pm}</h5>
              <ListBox
                value={targetOptionsSelected}
                onChange={(e) => setTargetOptionsSelected(e.value)}
                options={target}
                optionLabel="label"
                itemTemplate={itemTemplate}
                className="w-full md:w-14rem"
                listStyle={{ height: "350px" }}
                multiple
                filter
                filterBy="name"
                emptyFilterMessage={() => (
                  <label className="text-center d-block">
                    {dictionary.messages.no_results_found}
                  </label>
                )}
                emptyMessage={() => {
                  <label className="text-center d-block">
                    {dictionary.messages.no_results_found}
                  </label>;
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <Button
          type="button"
          className="btn btn-primary ms-3"
          onClick={deleteCompaniesInContractService}
        >
          {dictionary.actions.save}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Share;
