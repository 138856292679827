export const allSelectedWarehousesCanBeDeleted = (
  warehouseSelectedIds = [],
  warehouses = []
) => {
  const idSelected = warehouseSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const warehousesSelected = warehouses.filter((x) =>
    idSelected.includes(x.id)
  );
  return canAllWarehousesBeDeleted(warehousesSelected);
};

export const canWarehouseBeDeleted = (warehouse) => {
  // if (
  //   warehouse.status === "BOOKED" &&
  //   !warehouse.carrier.warehouseDeleteEnabled
  // ) {
  //   return false;
  // }
  return true;
};

export const canAllWarehousesBeDeleted = (warehouses) => {
  if (!warehouses || warehouses.length === 0) return false;
  const selectedWarehousesCanBeDeleted = warehouses.map((x) =>
    canWarehouseBeDeleted(x)
  );
  return selectedWarehousesCanBeDeleted.every((x) => x);
};

export const warehouseNormalizer = (warehouse, accessTokenPayload) => {
  warehouse.logisticIntegrationId = warehouse.logisticIntegration?.id;
  warehouse.ownerId = warehouse.ownerId || accessTokenPayload.owner;
  warehouse.locationId = warehouse.location?.id;
  return warehouse;
};
