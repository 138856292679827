import React, { useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faCloud,
  faEye,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { CreateEditOrderDetailProvider } from "./providers/CreateEdit";
import { CreateEdit } from "./CreateEdit";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody } from "reactstrap";
import { useIndexOrderDetailsContext } from "./providers/Index";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { useHandlerOrderContext } from "../Orders/providers/Handler";
import { DeleteOrderDetailProvider } from "./providers/Delete";
import Delete from "./Delete";
import { recalculateOrderValues } from "../Orders/utilities/index";

const OrderDetails = ({ action, renderFooter }) => {
  const listRef = useRef(null);
  const listProps = useListProps(action);

  const context = useIndexOrderDetailsContext();
  const handlerOrderContext = useHandlerOrderContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} action={action} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        parentId={context?.parentId}
        sorting={listProps.sorting}
        defaultPagination={listProps.defaultPagination}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
          renderFooter={renderFooter}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
          actions={listProps.actions}
        />
      </ListProvider>

      <Modal isOpen={context.modal.open} size={context?.modal.size}>
        <ModalBody>
          {context?.modal.action === "create" && (
            <CreateEditOrderDetailProvider
              parentId={context?.parentId}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );
                  newOrder.orderDetails = res.totalElements;

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "edit" && (
            <CreateEditOrderDetailProvider
              id={context.modal.data}
              parentId={context?.parentId}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );
                  newOrder.orderDetails = res.totalElements;

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "nextDetail" && (
            <CreateEditOrderDetailProvider
              id={context?.modal.id}
              parentId={context?.parentId}
              callback={(callNextDetail) => {
                context?.setModal({ open: false });
                listRef?.current?.refresh();
                if (callNextDetail) {
                  context?.nextDetail();
                }
              }}
              action={"nextDetail"}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteOrderDetailProvider
              data={context.modal.data}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );
                  newOrder.orderDetails = res.totalElements;

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <Delete />
            </DeleteOrderDetailProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderDetails;

const ListItem = React.memo(({ item, index, action }) => {
  const { dictionary } = useAppContext();

  const context = useIndexOrderDetailsContext();

  return (
    <React.Fragment key={index}>
      <div className="list-item px-2">
        <div
          className=" d-grid grid-column align-items-center"
          style={{
            gridTemplateColumns: `0.25fr 1.5fr 2.5fr 0.75fr 1fr 1fr 1fr 0.75fr 1.5fr 1.5fr`,
          }}
        >
          <div>
            {item.article?.shippingRequired ? (
              <FontAwesomeIcon
                icon={faBox}
                title={dictionary.products.physical_good}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCloud}
                title={dictionary.products.digital_good}
              />
            )}
          </div>
          <div>
            <label className="label-2">{dictionary.products.barcodes}:</label>
            <label className="label-3">{item.barcode || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary.registries.name}:</label>
            <label className={`label-3`}>{item.article?.name || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary.dimensions.quantity}:</label>
            <label className={`label-3`}>{item.quantity || "-"}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary.dimensions.unit_weight}:
            </label>
            <label className={`label-3`}>
              {item.unitWeight ? `${item.unitWeight?.toFixed(3)} kg` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary.dimensions.total_weight}:
            </label>
            <label className={`label-3`}>
              {item.totalWeight ? `${item.totalWeight?.toFixed(3)} kg` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.unit_price}:</label>
            <label className={`label-3`}>
              {item.unitPrice ? `${item.unitPrice?.toFixed(2)} €` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.discount}:</label>
            <label className={`label-3`}>
              {item.discount ? `${item.discount?.toFixed(2)} €` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.total_price}:</label>
            <label className={`label-3`}>
              {item.totalPrice ? `${item.totalPrice?.toFixed(2)} €` : "-"}
            </label>
          </div>

          <div className="text-end">
            {action !== "details" && (
              <React.Fragment>
                <ButtonUtility
                  className="ms-1"
                  title={dictionary.actions.details}
                  onClick={() => {
                    context?.setModal({
                      open: true,
                      title: `${dictionary.actions.details}`,
                      action: action,
                      data: item.id,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </ButtonUtility>
                <ButtonDelete
                  className="ms-1"
                  onClick={() => {
                    context?.setModal({
                      open: true,
                      title: `${dictionary.actions.delete}`,
                      action: "delete",
                      data: [item.id],
                      size: "sm",
                    });
                  }}
                >
                  {" "}
                </ButtonDelete>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
