import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faCalendar,
  faCheck,
  faClipboardList,
  faDownToBracket,
  faFileCsv,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { useIndexShipmentsContext } from "../providers/Index";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllCarriersFromCarrierConfigurations,
  getAllCompanies,
  getAllStatusCodes,
} from "../../../utilities/asyncSelectCallAPI";
import {
  getCarrierFromCarrierIntegrationOptionStyle,
  getCompanyOptionStyle,
  getParcelTypeOptionStyle,
  getShipmentStatusOptionStyle,
  getShipmentTypeOptionStyle,
} from "../../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../../utilities/types";
import moment from "moment";
import { orderBykey } from "../../../utilities";

const statusValidForDownload = [
  "BOOKED",
  "CONFIRMED",
  "AVAILABLE",
  "IN_TRANSIT",
  "OUT_OF_DLV",
  "EXCEPTION",
  "FAILED_DLV",
  "DELIVERED",
  "INFO_RCVD",
];
const statusValidForDelete = ["DRAFT", "BOOKED"];
const statusValidForConfirm = ["BOOKED"];
const statusValidForCollection = ["BOOKED", "CONFIRMED", "INFO_RCVD"];
const statusValidForDownloadManifest = ["CONFIRMED", "INFO_RCVD"];

export const isDisabledDownload = (selectedRows) =>
  !selectedRows.every((item) =>
    statusValidForDownload.includes(item.statusCode)
  );

export const isDisabledDownloadManifest = (selectedRows) =>
  !selectedRows.every(
    (item) =>
      statusValidForDownloadManifest.includes(item.statusCode) &&
      item.carrierCode === selectedRows[0]?.carrierCode &&
      item.pickupName === selectedRows[0]?.pickupName
  );

export const isDisabledDelete = (selectedRows) =>
  !selectedRows.every((item) => statusValidForDelete.includes(item.statusCode));

export const isDisabledConfirm = (selectedRows) =>
  !selectedRows.every((item) =>
    statusValidForConfirm.includes(item.statusCode)
  );

export const isDisabledCollection = (selectedRows) =>
  !selectedRows.every(
    (item) =>
      statusValidForCollection.includes(item.statusCode) &&
      item.collectionCreationEnabled &&
      !item.collectionAssigned &&
      item.carrierCode === selectedRows[0]?.carrierCode
  );

export const isDisabledReturn = (selectedRows) =>
  !selectedRows.every((item) => item.statusCode === "DELIVERED");

export const useListProps = () => {
  const navigate = useNavigate();

  const { dictionary } = useAppContext();

  const context = useIndexShipmentsContext();

  const {
    confirmationBoolOptions,
    confirmationOptions,
    parcels,
    shipments,
    shipmentStatus,
  } = useTypes();

  return {
    path: "/shipment-service/shipments",
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary.dates.creation_date,
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary.dates.creation_date,
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary.shipments.my_shipments,
    omnisearch: {
      enabled: true,
      placeholder: dictionary.references.references,
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary.dates.creation_date,
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    filters: {
      name: "shipments",
      preserve: false,
      list: [
        {
          fieldName: "senderId",
          label: dictionary.registries.sender,
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCompanies(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: true,
          optionStyle: getCompanyOptionStyle,
          primary: true,
        },
        {
          fieldName: "delivery.name",
          label: dictionary.registries.receiver,
          type: "text",
          preciseSearch: false,
          placeholder: "Allabox srl",
          primary: true,
        },
        {
          fieldName: "contractService.contract.carrierCode",
          label: dictionary.registries.carrier,
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCarriersFromCarrierConfigurations(
              searchValue,
              pagination,
              {
                orderBy: "asc",
                selector: "name",
              },
              [
                {
                  selector: "carrierName",
                  value: searchValue,
                },
              ]
            );
          },
          searchKey: "carrierCode",
          isMulti: false,
          optionStyle: getCarrierFromCarrierIntegrationOptionStyle,
          primary: true,
        },
        {
          fieldName: "status.code",
          label: dictionary.words.status,
          type: "select",
          options: orderBykey(shipmentStatus, "label"),
          isMulti: true,
          optionStyle: getShipmentStatusOptionStyle,
          primary: true,
        },

        {
          fieldName: "parcelType.code",
          label: dictionary.parcels.package_type_2,
          type: "select",
          options: orderBykey(parcels, "label"),
          isMulti: true,
          optionStyle: getParcelTypeOptionStyle,
          primary: false,
          col: 2,
        },
        {
          fieldName: "creationUser",
          label: dictionary.users.creation_user,
          type: "text",
          primary: false,
          col: 2,
          placeholder: "user@allabox.it",
        },
        {
          fieldName: "shipmentType",
          label: dictionary.shipments.shipment_typology,
          type: "select",
          options: orderBykey(shipments, "label"),
          isMulti: true,
          primary: false,
          col: 2,
        },
        {
          fieldName: "deliveryDate",
          label: dictionary.dates.delivery_date,
          type: "datepicker",
          primary: false,
          col: 2,
        },
        {
          fieldName: "cashOnDeliveryValue",
          label: dictionary.options.cash_on_delivery,
          type: "select",
          options: confirmationOptions,
          primary: false,
          col: 2,
        },
        {
          fieldName: "codCollected",
          label: dictionary.cod_collections.cod_collected,
          type: "select",
          disablingParams: [{ fieldName: "cashOnDeliveryValue" }],
          options: confirmationBoolOptions,
          primary: false,
          col: 2,
        },
        {
          fieldName: "insuranceValue",
          label: dictionary.options.insurance,
          type: "select",
          options: confirmationOptions,
          primary: false,
          col: 2,
        },
      ],
    },
    exports: [
      {
        title: dictionary.actions.export + " CSV",
        icon: <FontAwesomeIcon icon={faFileCsv} />,
        onClick: context.onExportCSV,
        isDisabled: context.exportLoader,
      },
    ],
    create: {
      enabled: true,
      action: () => {
        navigate("/shipments/create");
      },
      title: dictionary.shipments.new_shipment,
      icon: faPlus,
    },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.collections.book_collection,
            action: "collection",
            data: selectedRows.map((item) => item.id),
            carrierCode: selectedRows[0].carrierCode,
            pickupId: selectedRows[0].pickupId,
          }),
        isDisabled: isDisabledCollection,
        label: dictionary.collections.book_collection,
        icon: <FontAwesomeIcon icon={faCalendar} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.actions.confirm_download,
            action: "print",
            data: selectedRows.map((item) => item.id),
          }),
        isDisabled: isDisabledDownload,
        label: dictionary.shipments.print_labels,
        icon: <FontAwesomeIcon icon={faDownToBracket} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) => {
          localStorage.removeItem("manifest_filters");
          localStorage.setItem(
            "manifest_filters",
            JSON.stringify({
              internalReference: selectedRows.map((r) => r.internalReference),
              "contractService.contract.carrierCode":
                selectedRows[0].carrierCode,
            })
          );
          window.open("/shipments/manifest", "_blank", "noopener,noreferrer");
        },
        isDisabled: isDisabledDownloadManifest,
        label: dictionary.shipments.print_manifest,
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.shipments.confirm_shipment,
            action: "confirm",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledConfirm,
        label: dictionary.actions.confirm_selected_pm,
        icon: <FontAwesomeIcon icon={faCheck} />,
        className: "button-1 button-1-dark",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.actions.confirm_deletion,
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledDelete,
        label: dictionary.actions.delete_selected,
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
  };
};
